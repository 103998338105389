import React from 'react'
import OndemandLogo from '../../Assets/Image/ondemand_logo.png'
import "./footer.css"

import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <React.Fragment>
     <hr />
     <div className='footer container'>
          <div className="footer-left">
              <img src={OndemandLogo} alt="" />
              <h1>ที่อยู่บริษัท</h1>
              <div className="company-address">
                  <h2>บริษัท ออนดีมานด์ เอ็ดดูเคชั่น จำกัด (สำนักงานใหญ่)</h2>
                  <h2>ที่อยู่ 444 ชั้น 14 อาคารเอ็ม บี เค ทาวเวอร์ ถนนพญาไท</h2>
                  <h2>แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330</h2>
                  <div className="copyright">
                      <h3>©️ 2021 ONDEMAND EDUCATION CO.,LTD.</h3>
                      <h3>ALL RIGHTS RESERVED BY LEARN CORPORATION</h3>
                  </div>
              </div>
          </div>
          <div className="footer-right">
              <h1>CALL CENTER</h1>
              <h1>02-251-9456</h1>
              <h2>(08.00-20.00 น.)</h2>
              <div className="contact-list">
                  <h1>ติดตามเราได้ที่</h1>
                  <div className="contact-icons">
                      <FacebookIcon
                          className="contact-icon"
                          onClick={() => window.open("https://www.facebook.com/ondemandacademy")} />
                      <YouTubeIcon
                          className="contact-icon"
                          onClick={() => window.open("https://www.youtube.com/channel/UChKQvqb181PhQQse5PwBkrQ")} />
                      <InstagramIcon
                          className="contact-icon"
                          onClick={() => window.open("https://www.instagram.com/ondemandacademy/?hl=th")} />
                      <TwitterIcon
                          className="contact-icon"
                          onClick={() => window.open("https://twitter.com/ondemandacademy")} />
                  </div>
              </div>
          </div>
      </div>
      </React.Fragment>
  )
}

export default Footer
