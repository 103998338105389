import {uniquesList} from "../Interface/promotion.interface";
import axios from "axios";

const getPromotionLibraryUniqueList = async (userId: string, promotionId: string): Promise<uniquesList> => {
    const config = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        params: {userId, promotionId},
    };
    try {
        const res = await axios.get("/reward-promotion/library-unique-by-promotion", config);
        return res.data;
    } catch (err) {
        console.log(err);
        return {uniqueList: []};
    }
};

export default getPromotionLibraryUniqueList;
