import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Drawer, ListItem, List, ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import RedButton from '../RedButton/redbutton';
import OndemandLogo from '../../Assets/Image/ondemand_logo.png'
import AdminProfile from '../AdminProfile/adminProfile';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Loading from "../Loading/loading";

const drawerWidth = 280;

const AdminSidebar = () => {
    const itemsList = [
        // {
        //     text: 'User',
        //     icon: <PersonOutlineIcon />,
        //     onClick: () => {
        //         window.location.href = '/console/user';
        //     }
        // },
        {
            text: 'Campaign',
            icon: <ElectricBoltIcon />,
            onClick: () => {
                window.location.href = '/console/campaign'
            }
        },
        // {
        //     text: 'Report',
        //     icon: <AssessmentIcon />,
        //     onClick: () => {
        //         window.location.href = '/console/report';
        //     }
        // },
    ];

    const savedAdminName = localStorage.getItem('adminName');
    const [adminName, setAdminName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        if (savedAdminName) {
            const name = JSON.parse(savedAdminName);
            const adminNameSplit = name.split(' ')[0];
            setAdminName(adminNameSplit);
        }
        setIsLoading(false);
    }, [savedAdminName]);

    const handleLogout = () => {
        localStorage.removeItem('adminId');
        localStorage.removeItem('adminName');
        window.location.href = '/console';
    }
    
  return (
    <React.Fragment>
    <Loading isLoading={isLoading} />
    <Drawer variant='permanent'>
    <Box
        sx={{
        width: drawerWidth,
        flexShrink: 0,
        marginTop: "50px",
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{marginBottom: '50px', width: '180px'}}>
            <img src={OndemandLogo} alt="logo" />
        </Box>
        </Box>
        <Divider />
      <AdminProfile name={adminName} />
        <Divider />
      <List 
        sx={{
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}
      >
        {itemsList.map((item, index) => {
            const { text, icon, onClick } = item;
            return (
                <ListItem disablePadding key={text} onClick={onClick}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                >
                    <ListItemButton
                    sx={{
                        color: "#000000",
                        fontWeight: "600",
                        letterSpacing: "1px",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                    }}
                    >
                        <ListItemIcon
                        sx={{
                            color: "#000000",
                            fontSize: "50px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            left: "40px",
                        }}
                        >{icon}</ListItemIcon>
                        <ListItemText primary={text} 
                        sx={{
                            textAlign: "center",
                            position: "relative",
                            right: "10px",
                            '.css-ep52bf-MuiTypography-root': {
                                fontSize: "20px",
                            },
                        }}
                        />
                    </ListItemButton>
                </ListItem>
            ); 
        })}
      </List>
        <Divider />
      <Box 
        sx= {{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
            marginBottom: "20px",
        }}
      >
      <RedButton 
        content="Logout"
        onClick={() => handleLogout()}
      />
      </Box>
    </Box>
    </Drawer>
    </React.Fragment>
    );
};

export default AdminSidebar;
