import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';
import { FullDescriptionProps } from '../../Interface/fullDescription.interface'
import parse from "html-react-parser";


const ScrollDialog = ({ optiondata, description }: FullDescriptionProps) => {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

    const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <React.Fragment>
            <Typography
                onClick={handleClickOpen("paper")}
                sx={{
                    width: "100%",
                    height: "100%",
                    color: "#D7282F",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "none",
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "underline",
                    ":hover": { color: "#C30715" },
                }}
            >
                เงื่อนไขกิจกรรม
            </Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-data"
                sx={{
                    "& .MuiDialog-paper": {
                        color: "white",
                        borderRadius: "15px",
                        overflow: "auto",
                    },
                }}
            >
                <DialogTitle id="scroll-dialog-title">
                    <CancelIcon
                        className="cancelIcon"
                        onClick={handleClose}
                        sx={{
                            cursor: "pointer",
                            float: "right",
                            fontSize: "20px",
                            color: "black",
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-data"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography
                            sx={{
                                color: "#231F20",
                                marginBottom: "20px",
                                padding: "0px 20px",
                            }}
                        >
                            {parse(description)}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default ScrollDialog;
