import { Navigate, Outlet } from 'react-router-dom'
import {useCookies} from "react-cookie";

const PrivateRoutes = () => {
    const [cookies] = useCookies(['_token']);
    let auth = {'token':cookies._token}
    return (
        auth.token ? <Outlet/> : <Navigate to='/'/>
    )
}

export default PrivateRoutes;
