import { Button, Typography } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

interface IconPicButtonProp {
  content: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => any;
}

const IconPicButton = ({ content, onClick }: IconPicButtonProp) => {
  return (
    <Button
      startIcon={<AddPhotoAlternateOutlinedIcon/>}
      onClick={onClick}
      sx={{
        bgcolor: "#ffffff",
        border: 1,
        borderColor: "#DDE2E5",
        borderRadius: "20px",
        fontSize: "12px",
        fontWeight: "bold",
        color: "#231F20",
        "&:hover": {
          backgroundColor: "#DDE2E5",
        },
        marginBottom: "20px",
        width: "200px",
        height: "50px",
      }}
    >
      {
        <Typography sx={{ fontSize: "15px", fontWeight: "bold", color: "#231F20" }}>
            {content}
        </Typography>
      }
    </Button>
  );
};

export default IconPicButton;
