import axios from "axios";

const deleteCampaign = async (id: number, userId: number) => {
    try {
        const res = await axios.post('/reward-promotion/delete-campaign', {
            id,
            userId,
        },
        {baseURL: process.env.REACT_APP_API_BASE_URL},
        );
        return res.data;
    } catch (error) {
        return {
            statusCode: 500,
            message: "Something went wrong.",
        };
    }
}

export default deleteCampaign
