import "./addNewAdmin.css";

// import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import getAdminInfo from "../../../Services/getAdminInfo";
import RedButton from "../../../Components/RedButton/redbutton";

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },

    {
        field: "firstName",
        headerName: "First Name",
        width: 150,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        width: 150,
    },
    {
        field: "email",
        headerName: "Email",
        width: 150,
    },
    {
        field: "lastLogin",
        headerName: "Last Login",
        width: 100,
    },
    {
        field: "isActive",
        headerName: "Status",
        type: "boolean",
        width: 80,
        editable: true,
    },
];

const UserAdminList = () => {
    // const [adminList, setAdminList] = useState([]);
    // const getAdminList = async () => {
    //     const data = await getAdminInfo();
    //     console.log(data);
    //     setAdminList(data);
    // };
    // useEffect(() => {
    //     getAdminList();
    // }, []);

    // const rows = [...adminList];

    const [pageSize, setPageSize] = useState<number>(5);

    const rows = [
        {
            id: 1,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
        {
            id: 2,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
        {
            id: 3,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
        {
            id: 4,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
        {
            id: 5,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
        {
            id: 6,
            firstName: "Snow",
            lastName: "Jon",
            email: "11@11.com",
            lastLogin: "20-10-2022",
            isActive: true,
        },
    ];

    return (
        <div className="adminlist-contrainer">
            <div className="admimlist-title">
                <h1>Admin Account</h1>
            </div>
            <div className="adminlist-header">
                <RedButton
                    onClick={() => window.open("/newadmin")}
                    content={"Add New Admin"}
                />
            </div>

            <div className="adminlist-table">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </div>
        </div>
    );
};
export default UserAdminList;
