import axios from "axios";

const axiosConfig = {baseURL: process.env.REACT_APP_API_BASE_URL};

const currentPoint = async (
    userId: string,
): Promise<number> => {
    try {
        const res = await axios.get(
            "/point-engine/current",
            {
                ...axiosConfig, params: {
                    userId: userId
                }
            }
        );
        return res?.data;
    } catch (err) {
        console.log(err);
        return 0;
    }
};

export {currentPoint};
