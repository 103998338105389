import {UserObjectInfo} from "../Interface/user.interface";

const useUserInfo = () => {
    const setUseInfo = (data: UserObjectInfo) => {
        localStorage.setItem('userInfo', JSON.stringify(data))
    }
    const getUserInfo = (): UserObjectInfo => {
        const rawData = localStorage.getItem('userInfo')
        const data = rawData && JSON.parse(rawData)
        return {
            userId: data?.userId,
            fullname: data?.fullname,
            point: data?.point
        }
    }
    return {
        getUserInfo,
        setUseInfo
    }
}
export default useUserInfo;
