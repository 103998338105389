
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IBackButton {
    navigateTo?: string;
}

const BackButton = ({ navigateTo }: IBackButton) => {
  const navigate = useNavigate(); 

  return (
    <React.Fragment>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <ArrowBackIcon sx={{ marginRight: "10px" }} onClick={() => navigate(navigateTo? navigateTo : '..')} />
            <Typography variant="h5" sx={{ color: "#000000", fontWeight:"600" }} onClick={() => navigate(navigateTo? navigateTo : '..')}>Back</Typography>
        </Box>
    </React.Fragment>
  );
};

export default BackButton;
