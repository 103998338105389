import React, { useEffect, useState } from "react";
import PromotionNameDisplay from "../../Components/PromotionNameDisplay/PromotionNameDisplay";
import { useLocation } from "react-router-dom";
import { IPrmotionList } from "../../Interface/promotion.interface";
import CancelButton from "../../Components/Button/cancelBtn";
import DeleteCampaign from "../../Services/deleteCampaign";
import { Box } from "@mui/material";
import Loading from "../../Components/Loading/loading";
import ConfirmationDialog from "../../Components/ConfirmationDialog/confirmationDialog";
import ErrorAlert from "../../Components/ErrorAlert/errorAlert";
import SuccessAlert from "../../Components/SuccessAlert/successAlert";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../Components/BackButton/backButton";

const DeleteCampaignContainer = () => {
    const { state } = useLocation();
    const [promotionInfo, setPromotionInfo] = useState<IPrmotionList>();
    useEffect(() => {
        if (!promotionInfo) {
            setPromotionInfo(state);
        }
    },[state]);

    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const savedAdminId = localStorage.getItem("adminId");
    const adminIdJSON = JSON.parse(savedAdminId || "0");
    const adminId = Number(adminIdJSON);
    const campaignId = Number(id);

    console.log("campaignId", campaignId);
    console.log("adminId", adminId);
    

    

    const handleDelete = async () => {
        handleClose();
        setIsLoading(true);
        const response = await DeleteCampaign(campaignId, adminId);
        if (response.statusCode === 200) {
            setIsLoading(false);
            setAlertOpen(true);
            setAlertContent('Delete campaign successfully');
            setTimeout(() => {
                setAlertOpen(false);
                navigate('../..');
            }, 1000);
        }
        else {
            setIsLoading(false);
            setAlertOpen(true);
            setAlertContent(response.message);
            setTimeout(() => {
                setAlertOpen(false);
            }, 1000);
        }
    };

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <Box 
                sx={{ position: "absolute", top: "0", right: "0" }}>
                {
                    alertOpen && alertContent === 'Delete campaign successfully' ? <SuccessAlert open={alertOpen} content={alertContent} /> : <ErrorAlert open={alertOpen} content={alertContent} />
                }
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", position : "relative", top: "10px", left: "10px" }}>
                <BackButton />
            </Box>
            <PromotionNameDisplay
                promotionName={promotionInfo?.name}
                imageUrl={promotionInfo?.imageUrl}
                title="Delete Campaign"
            />

            <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                           
                            <ConfirmationDialog text="Confirm" confirmText="Confirm delete campaign" onClick={handleDelete}  handleClose={handleClose} handleOpen={handleOpen} open={open} setOpen={setOpen} />

                           
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                            <CancelButton content="Cancel" onClick={() => navigate('../..')} />
                        </Box>
                    </Box>

        </React.Fragment>
    );
};
export default DeleteCampaignContainer;
