import { Outlet, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/landingPage";
import Promotions from "./Pages/promotions";
import AdminLoginPage from "./Pages/adminloginpage";
import { CookiesProvider } from "react-cookie";
import { initializeApp } from "firebase/app";
import { config } from "./config/firebaseconfig";
import AuthRoute from "./Utils/authroute";
import UserAdminList from "./Containers/Admin/addNewAdmin/addNewAdmin";
import OauthToCallback from "./Pages/oauth-to-callback";
import PrivateRoutes from "./Utils/private-rotes";
import CreateCampaign from "./Containers/CreateCampaign";
import CampaignList from "./Containers/CampaignList";
import AdminMainPage from "./Pages/adminMainPage";
import CreatePromotion from "./Containers/CreatePromotion";
import EditPromotion from "./Containers/EditPromotion";
import EditCampaign from "./Containers/EditCampaign";
import PromotionList from "./Containers/PromotionList";
import CreateUniqueCode from "./Containers/CreateUniqueCodeContainer/CreateUniqueCodeContainer";
import DeletePromotion from "./Containers/DeletePromotionContainer/deletePromotioncontainer";
import DeleteCampaign from "./Containers/deleteCampaignContainer/deleteCampaignContainer";
import ConfirmationDialog from "./Components/ConfirmationDialog/confirmationDialog";

initializeApp(config.firebaseConfig);

export interface IApplicationProps {}

const AppRouter = () => {
    return (
        <>
            <CookiesProvider>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route
                        path="/oauth2callback"
                        element={<OauthToCallback />}
                    />
                    <Route element={<PrivateRoutes />}>
                        <Route path="/promotion" element={<Promotions />} />
                    </Route>
                    <Route path="/list" element={<UserAdminList />} />
                    <Route path="/console" element={<Outlet />}>
                        <Route index element={<AdminLoginPage />} />
                        <Route
                            path="campaign"
                            element={
                                <AuthRoute>
                                    <AdminMainPage />
                                </AuthRoute>
                            }
                        >
                            <Route index element={<CampaignList />} />
                            <Route path="create" element={<CreateCampaign />} />
                            <Route path=":id">
                                <Route path="editcampaign" element={<EditCampaign />} />
                                <Route path="deletecampaign" element={<DeleteCampaign />} />
                                <Route index element={<PromotionList />} />
                                <Route
                                    path="create"
                                    element={<CreatePromotion />}
                                />
                                <Route
                                    path="createuniquecode"
                                    element={<CreateUniqueCode />}
                                />
                                <Route path="editpromotion" element={<EditPromotion />} />
                                <Route path="deletepromotion" element={<DeletePromotion />} />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </CookiesProvider>
        </>
    );
};

export default AppRouter;
