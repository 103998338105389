import React from 'react';
import './loading.css';
import Backdrop from '@mui/material/Backdrop';

interface LoadingProp {
    isLoading: boolean;
}

const Loading = (prop: LoadingProp) => {
    return (
        <React.Fragment>
        {prop.isLoading
            ?
                <React.Fragment>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={prop.isLoading}
                    >
                    <div className='block-ui-container'></div>
                    <div className="loading-spinner"></div>
                    </Backdrop>
                </React.Fragment>
            : <React.Fragment></React.Fragment>
          }
        </React.Fragment>
    );
}

export default Loading
