import React, {useEffect, useState} from "react";
import {LibraryPromotion, PromotionInfo} from "../../Interface/promotion.interface";
import {UserObjectInfo} from "../../Interface/user.interface";
import UserProfile from "../../Components/Userprofile/userProfile";
import CouponCard from "../../Components/CouponCard/couponCard";
import EventConditionDialog from "../../Components/EventConditionDialog/EventConditionDialog";
import LoadingPage from "../../Components/Loading/loading";
import getPromotionList from "../../Services/getPromotionList";
import {useQuery} from "../../Utils";
import {useUserInfo} from "../../Hooks"
import "./promotion.css";
import getPromotionLibraryList from "../../Services/getPromotionLibraryList";
import ViewLibraryButtonGroup from "../../Components/ViewLibraryButtonGroup/viewLibraryButtonGroup";
import RedeemSuccessDialog from "../../Components/RedeemSuccessDialog/RedeemSuccessDialog";
import getPromotionLibraryUniqueList from "../../Services/getPromotionLibraryUniqueList";
import {currentPoint} from "../../Services/user.service";

const PromotionsContainer = () => {
    const {getUserInfo} = useUserInfo()
    const initialPromotionData: PromotionInfo = {
        id: 0,
        campaignId: 0,
        promotionName: '',
        promotionType: '',
        promotionGroup: '',
        buCode: '',
        description: '',
        point: 0,
        imageUrl: '',
        canRedeem: false,
        endDate: ''

    };
    const [userInfo, setUserInfo] = useState<UserObjectInfo>({
        userId: '',
        point: 0,
        fullname: '',
    })
    const [couponDialogOpen, setCouponDialogOpen] = useState(false);
    const [couPonData, setCouPonData] =
        useState<PromotionInfo>(initialPromotionData);
    const [couponDatas, setCouponDatas] = useState<any>({});
    const [myCoupon, setMyCoupon] = useState({});
    const [myUnique, setMyUnique] = useState<{
        open: boolean, uniques: string[],
        promotionGroup: string, expiredDate: string
    }>({
        open: false,
        uniques: [],
        promotionGroup: '',
        expiredDate: '',
    });
    const [isLoading, setLoading] = useState(true);
    const [userObj, setUserObj] = useState({});
    const code = useQuery("code") || "";
    const [viewLibrary, setViewLibrary] = useState(false)
    const getPromotion = () => {
        getPromotionList(userInfo?.userId).then((dataList) => {
            setCouponDatas(dataList)
            setLoading(false)
        })
        setUserInfo(getUserInfo());
    };

    const getCurrentPoint = async () => {
        currentPoint(userInfo?.userId).then((point: number) => {
            setUserInfo((prevState:UserObjectInfo) => {
                return ({
                    ...prevState,
                    point: point,
                })
            })
        })

    }

    // Get data from userPortal
    useEffect(getPromotion, [code]);
    useEffect(() => {
        if (viewLibrary && userInfo) {
            getPromotionLibraryList(userInfo?.userId).then((data: LibraryPromotion[]) => {
                setMyCoupon(data);
            })
        } else {
            getPromotionList(userInfo?.userId).then((data: any) => {
                setCouponDatas(data)
            })
        }
        getCurrentPoint()
    }, [viewLibrary, userInfo?.userId]);
    return (
        <React.Fragment>
            <LoadingPage isLoading={isLoading}/>
            <UserProfile
                fullname={userInfo?.fullname}
                point={userInfo?.point}
                userId={userInfo.userId}
            />
            <div className="viewlibrary-container">
                <ViewLibraryButtonGroup setViewLibrary={setViewLibrary}/>
            </div>
            <div className="container cardlist-container">
                <div className="card-list">
                    {viewLibrary && myCoupon
                        ? Object.entries(myCoupon).map(
                            ([category, value]: any, index: number) => {
                                return (
                                    <React.Fragment>
                                        {value.map((data: any) => {
                                            return (
                                                <React.Fragment>
                                                    <CouponCard
                                                        key={`card${index}`}
                                                        id={data.promotionId.toString()}
                                                        imageUrl={
                                                            data.promotionImg ||
                                                            "http://redemption.ondemand.in.th/static/media/reward/2021/09/29/12/4a42/20211008-094910.thumbnail%20shoponline%201280x720%20px-02.png"
                                                        }
                                                        promotionType={
                                                            data.promotionType
                                                        }
                                                        promotionName={
                                                            data.promotionName
                                                        }
                                                        onClick={async () => {
                                                            getPromotionLibraryUniqueList(
                                                                userInfo.userId,
                                                                data.promotionId
                                                            ).then(
                                                                (uniList) => {
                                                                    setMyUnique(
                                                                        {
                                                                            open: !!uniList.uniqueList,
                                                                            uniques:
                                                                            uniList.uniqueList,
                                                                            promotionGroup:
                                                                            data.promotionGroup,
                                                                            expiredDate:
                                                                            data.endDate,
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        point={
                                                            data.promotionPoint
                                                        }
                                                        viewFlag={viewLibrary}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            }
                        )
                        : Object.values(couponDatas).map((type: any) =>
                            type.map((data: PromotionInfo, index: number) => {
                                return (
                                    <React.Fragment>
                                        <CouponCard
                                            key={`card${index}`}
                                            id={data.id.toString()}
                                            imageUrl={
                                                data.imageUrl ||
                                                "http://redemption.ondemand.in.th/static/media/reward/2021/09/29/12/4a42/20211008-094910.thumbnail%20shoponline%201280x720%20px-02.png"
                                            }
                                            promotionType={data.promotionType}
                                            promotionName={data.promotionName}
                                            point={data?.point}
                                            disable={!data.canRedeem}
                                            onClick={() => {
                                                setCouPonData({
                                                    ...data,
                                                    imageUrl:
                                                        data.imageUrl ||
                                                        "http://redemption.ondemand.in.th/static/media/reward/2021/09/29/12/4a42/20211008-094910.thumbnail%20shoponline%201280x720%20px-02.png",
                                                });
                                                setCouponDialogOpen(true);
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })
                        )}
                    <EventConditionDialog
                        code={code}
                        key="EventConditionDialog"
                        open={couponDialogOpen}
                        setOpen={setCouponDialogOpen}
                        loading={isLoading}
                        setLoading={setLoading}
                        setCouponDatas={setCouponDatas}
                        setUserObj={setUserObj}
                        promotionData={couPonData}
                        userObject={userInfo}
                        loadUserProfileInfo={getPromotion}
                        onClose={(e: any) => {
                            e.preventDefault();
                            setCouponDialogOpen(false);
                        }}
                    />
                    <RedeemSuccessDialog
                        open={myUnique.open}
                        uniCode={myUnique.uniques}
                        onClose={(e) => {
                            e.preventDefault();
                            setMyUnique({
                                open: false,
                                uniques: [],
                                promotionGroup: "",
                                expiredDate: "",
                            });
                        }}
                        viewFlag={viewLibrary}
                        promotionGroup={myUnique.promotionGroup}
                        endDate={myUnique.expiredDate}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default PromotionsContainer
