import React from 'react'
import { Box, Typography } from '@mui/material'

interface PromotionNameProps {
    promotionName?: string
    promotionId?: number
    imageUrl?: string
    title?: string
}

const PromotionNameDisplay = ({ promotionName, promotionId, imageUrl, title }: PromotionNameProps) => {
  return (
    <Box 
    sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        marginTop: "20px",
        marginBottom: "20px",
        padding: "20px",
    }}>
        <Typography variant="h4" component="div"
                                sx={{
                                    color: "#000000",
                                    letterSpacing: "1px",
                                    flexGrow: 1,
                                    display: "flex",
                                    position: "relative",
                                    top: "10px",
                                    left: "10px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                }}>
                                {title}
                                </Typography>
        <Typography variant="h4" sx={{ marginBottom: "40px", marginLeft: "20px"}}>
            {promotionName}
        </Typography>
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                flexDirection: "column",
                width: "40%",
            }}
        >
            {imageUrl && <img src={imageUrl} alt="promotion" />}
        </Box>
    </Box>
  )
}

export default PromotionNameDisplay
