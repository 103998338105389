import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/system';

interface ViewLibraryButtonProp {
    setViewLibrary: (viewLibrary: boolean) => void;
}

const ViewLibraryButtonGroup = ({ setViewLibrary }: ViewLibraryButtonProp) => {
  const [alignment, setAlignment] = useState('point');
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
        setAlignment(newAlignment);

        if (newAlignment === 'point') {
            setViewLibrary(false);
        } else {
            setViewLibrary(true);
        }
    }
    };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: "30px", marginBottom: '30px'}}>
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
    >
      <ToggleButton value="point" onClick={() => setViewLibrary(false)}>แลกคะแนน</ToggleButton>
      <ToggleButton value="reward" onClick={() => setViewLibrary(true)}>รางวัลของฉัน</ToggleButton>
    </ToggleButtonGroup>
    </Box>
  );
}

export default ViewLibraryButtonGroup;

