import axios from "axios";
import {
    ICreatePromotion,
    IPrmotionList,
} from "../Interface/promotion.interface";

import { ICreateCampaignResponse, IUpdateCampaign } from "../Interface/campaign.interface";


const axiosConfig = { baseURL: process.env.REACT_APP_API_BASE_URL };
const createPromotion = async (
    data: ICreatePromotion | undefined,
    userId: number
): Promise<ICreateCampaignResponse>  => {
    
    try {
        const body = { ...data, userId };
        const res = await axios.post(
            "/reward-promotion/create-promotion",
            body,
            axiosConfig
        );
        return res.data;
    } catch (err) {
        return {
            statusCode: 500,
            message: "Something went wrong."
          }
    }
};

const updatePromotion = async ( data: ICreatePromotion | undefined, userId: number ): Promise<ICreateCampaignResponse> => {
    try {
        const body = { ...data, userId };
        const res = await axios.post(
            "/reward-promotion/update-promotion",
            body,
            axiosConfig
        );
        return res.data;
    } catch (err) {
        return {
            statusCode: 500,
            message: "Something went wrong."
            }
    }
};

const updateCampaign = async ( data: IUpdateCampaign | undefined, userId: number ): Promise<ICreateCampaignResponse> => {
    try {
        const body = { ...data, userId };
        const res = await axios.post(
            "/reward-promotion/update-campaign",
            body,
            axiosConfig
        );
        return res.data;
    } catch (err) {
        return {
            statusCode: 500,
            message: "Something went wrong."
            }
    }
};

const getPromotionByCampaign = async (
    id: number
): Promise<IPrmotionList[] | []> => {
    try {
        const res = await axios.get("/reward-promotion/promotionsByCampaign", {
            params: { campaignId: id },
            baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export { createPromotion, getPromotionByCampaign, updatePromotion, updateCampaign };
