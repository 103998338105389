import { Button } from "@mui/material";

interface CTLButtonProp {
  content: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => any;
  fontSize?: string;
}

const CTLButton = ({ content, onClick, fontSize }: CTLButtonProp) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx = {{
        backgroundColor: "#ffffff",
        color: "#000000",
        borderRadius: "50px",
        paddingLeft: "30px",
        paddingRight: "30px",
        height: "50px",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: fontSize ? fontSize : "25px",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#DDE2E5",
         },
         "&.MuiButton-outlined": {
            border: "1px solid #DDE2E5",
            }
      }}
    >
      {content}
    </Button>
  );
};

export default CTLButton;
