import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from "@mui/system";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";

  interface RedeemFailedBoxProp {
    onClose: (e: any) => void;
    open: boolean;
}

const redeemFailedBox = ({ onClose, open }: RedeemFailedBoxProp) => {
  return (
    <React.Fragment>
        <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                sx={{
                "& .MuiDialog-paper": {
                    color: "white",
                    borderRadius: "50px",
                    height: "max-content",
                },
                }}
            >
        <DialogTitle sx={{textAlign: "center"}}>
        <CancelIcon
            onClick={onClose}
            sx={{cursor: 'pointer', float: 'right', fontSize: '20px', color: 'black'}}/>
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="redeem-event-item"
        sx={{
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
        height: "max-content",
        }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            top: "-15px"
                        }}
                    >
                    <CancelIcon
                        sx={{ fontSize: 100, color: "red", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    />
                    <h1 style={{ fontWeight: 300 }}>แลกคะแนนไม่สำเร็จ !</h1>
                    <h2 style={{ fontWeight: 300 }}>กรุณาลองใหม่อีกครั้ง</h2>
                    <h2 style={{ fontWeight: 300 }}>หรือติดต่อ Call Center</h2>
                    </Box>

        </DialogContentText>
        </DialogContent>
        </Dialog>
    </React.Fragment>
  )
}

export default redeemFailedBox
