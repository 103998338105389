import React, {useEffect, useRef, useState} from "react";
import getUserInfo from "../Services/getUserInfo";
import {useQuery} from "../Utils";
import {AccessTokenObjectInfo} from "../Interface/user.interface";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import Loading from "../Components/Loading/loading";
import {useUserInfo} from "../Hooks"

const OauthToCallback = () =>{
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    let {setUseInfo} = useUserInfo();
    const [cookies, setCookie] = useCookies();
    let [openLoading , setOpenLoading] = useState<boolean>(false)
    const code = useQuery("code") || "";
    const loadUserProfileInfo = () => {
        getUserInfo(code, `${window.location.origin}/oauth2callback`)
            .then((data: AccessTokenObjectInfo) => {
                setCookie('_token',data.Token,{maxAge: data.expires})
                setUseInfo(data.userInfo);
            })
            .then(() => {
                setOpenLoading(false)
                return navigate("/promotion");
            }).catch((error) => {
            return navigate("/");
        })
    };

    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setOpenLoading(true)
        loadUserProfileInfo()
    })

    return(<>
        <Loading isLoading={openLoading}/>
    </>)
}

export default OauthToCallback
