import * as React from "react";
import {useState} from "react";
import "./style.css";
import dayjs, {Dayjs} from "dayjs";
import UploadImageDialog from "../UploadImageDialog/uploadImageDialog";
import {Grid, Typography} from "@mui/material";
import IconPicButton from "../../Components/Button/iconPicBtn";
import IOSSwitch from "../../Components/Switch/switch";
import CTLButton from "../../Components/Button/cancelBtn";
import { useNavigate, useLocation } from "react-router-dom";
import AutocompleteField from "../../Components/AutocompleteField/autocompleteField";
import InputField from "../../Components/InputField/inputfield";
import DatePickerField from "../../Components/DatePickerField";
import {createCampaign} from "../../Services/campaign.service";
import {ICampaign} from "../../Interface/campaign.interface";
import Wysiwyg from "../../Components/Wysiwyg/wysiwyg";
import Loading from "../../Components/Loading/loading";
import ConfirmationDialog from "../../Components/ConfirmationDialog/confirmationDialog";
import SuccessAlert from "../../Components/SuccessAlert/successAlert";
import ErrorAlert from "../../Components/ErrorAlert/errorAlert";
import { Box } from "@mui/system";
import { DeleteForever } from "@mui/icons-material";
import RedButton from "../../Components/RedButton/redbutton";
import BackButton from "../../Components/BackButton/backButton";

const CampaignType = ["ShopOnline", "Goods", "Special"];

const CreateCampaign = () => {
    const navigate = useNavigate();
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [imgBase64, setImgBase64] = useState<{ data: string; preview: string } | undefined>();
    const [name, setName] = useState<string>("");
    const [startDate, setStartDate] = useState<Dayjs>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(1, 'year'));
    const [isActive, setIsActive] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");
    const [campaignTypeSelected, setCampaignTypeSelected] = useState(CampaignType[0]);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const onCreateCampaign = async () => {
        const data: ICampaign = {
            name: name,
            description: description,
            imageBase64: imgBase64?.data || '',
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            isActive: isActive,
            type: campaignTypeSelected,
        };
        handleClose();
        setLoading(true);
        const response = await createCampaign(data);
        if (response.statusCode === 200) {
            setLoading(false);
            setAlertOpen(true);
            setAlertContent('Create campaign successfully');
            setTimeout(() => {
                setAlertOpen(false);
                navigate('..');
            }, 1000);
        } else {
            setLoading(false);
            setAlertOpen(true);
            setAlertContent(response.message);
            setTimeout(() => {
                setAlertOpen(false);
            }, 1000);
        }
    }


    return (<>
        <Loading isLoading={isLoading}/>
        <Box 
            sx={{ position: "absolute", top: "0", right: "0"}} >
                {
                    alertOpen && alertContent === 'Create campaign successfully' ? <SuccessAlert open={alertOpen} content={alertContent}/> : <ErrorAlert open={alertOpen} content={alertContent}/>
                }
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", position : "relative", top: "10px", left: "10px" }}>
                <BackButton />
            </Box>
        <div className="Admin-background  content">
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item justifyContent="flex-start" alignItems="center">
                    <Typography variant="h4" component="div"
                                sx={{
                                    color: "#000000",
                                    letterSpacing: "1px",
                                    flexGrow: 1,
                                    display: "flex",
                                    position: "relative",
                                    top: "10px",
                                    left: "10px",
                                    fontWeight: "bold",

                                }}>
                        Create New Campaign
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="stretch"
                    direction="row"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        sm={6}
                    >
                        <Grid item>
                            <AutocompleteField
                                text={'Type'}
                                width={'90%'}
                                value={campaignTypeSelected}
                                onChange={(data) => setCampaignTypeSelected(data)}
                                options={CampaignType}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                width={'90%'}
                                text={"Name"}
                                value={name}
                                onChange={(data) => setName(data)}
                            />
                        </Grid>
                        <Grid item>
                            <DatePickerField
                                text={"Start Date"}
                                value={startDate}
                                resultDate={(data) => setStartDate(data)}
                                width={'90%'}
                            />
                        </Grid>
                        <Grid item>
                            <DatePickerField
                                text={"End Date"}
                                value={endDate}
                                resultDate={(data) => setEndDate(data)}
                                isEndDate={true}
                                width={'90%'}
                            />
                        </Grid>
                        <Grid>
                            <div className="switch1">
                                <Typography variant="h6" component="div"
                                            sx={{
                                                color: "#000000",
                                                fontSize: "25px",
                                                letterSpacing: "1px",
                                                flexGrow: 1,
                                                display: "flex",
                                                position: "relative",
                                                left: "20px",
                                            }}
                                >
                                    {'Status '}
                                </Typography>
                                <IOSSwitch
                                    checked={isActive}
                                    onChange={(event) => setIsActive(event.target.checked)}
                                    name="isActive"
                                    sx = {{position: "relative", left: "-140px"}}
                                />
                                {
                                    isActive ? <Typography variant="h6" component="div" sx={{
                                        fontSize: "20px",
                                        letterSpacing: "1px",
                                        flexGrow: 1,
                                        display: "flex",
                                        position: "relative",
                                        left: "-130px",
                                    }}>Enable</Typography> : <Typography variant="h6" component="div" sx={{
                                        fontSize: "20px",
                                        letterSpacing: "1px",
                                        flexGrow: 1,
                                        display: "flex",
                                        position: "relative",
                                        left: "-130px",
                                    }}>Disable</Typography>

                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        sm={6}
                    >
                        <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Typography variant="h6" component="div"
                                        sx={{
                                            color: "#000000",
                                            fontSize: "25px",
                                            letterSpacing: "1px",
                                            flexGrow: 1,
                                            display: "flex",
                                            position: "relative",
                                            left: "20px",
                                            marginBottom: "10px",
                                            marginTop: "10px"
                                        }}
                            >
                                {'Description '}
                            </Typography>
                            <Wysiwyg
                                editorStyle={{border: "1px solid #DDE2E5", borderRadius: "5px", height: "200px", paddingLeft: "20px", paddingRight: "30px"}}
                                htmlContent={description}
                                setHtmlContent={setDescription}/>
                        </Grid>
                        <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-start">
                            <Typography variant="h6" component="div"
                                        sx={{
                                            color: "#000000",
                                            fontSize: "25px",
                                            letterSpacing: "1px",
                                            flexGrow: 1,
                                            display: "flex",
                                            position: "relative",
                                            left: "20px",
                                            marginTop: "15px",
                                        }}
                            >
                                {'Image '}
                                <Typography sx = {{fontSize: "15px", color: "#000000", letterSpacing: "1px", position: "relative", left: "10px", top: "5px"}}>
                                    <span style={{color: "#FF0000"}}>*</span> Required
                                </Typography>
                            </Typography>
                            <div>
                                {imgBase64 && imgBase64.preview ? (
                                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Box sx={{marginTop: "15px"}}>
                                    <img
                                        style={{width: "80%"}}
                                        src={imgBase64.preview}
                                        alt={name}
                                    />
                                    </Box>
                                    <Box sx={{position: "relative", left: "-50px", top: "10px"}}>
                                        <DeleteForever onClick={() => setImgBase64({data: '', preview: ''})} sx={{color: "red", fontSize: "35px", cursor: "pointer"}}/>
                                    </Box>
                                    </Box>
                                ) : null}
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {
                                        imgBase64 && imgBase64.preview ? null : 
                                        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
                                        <Grid item sm={6}>
                                            <div className="button-add">
                                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-12px"}}>
                                                    <IconPicButton
                                                        content="Upload image"
                                                        onClick={() => setOpenUpload(true)}
                                                    />
                                                </Box>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    }
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <hr/>
                </Grid>
                <Grid item container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{marginBottom: "20px"}}
                >
                    <div>
                        <CTLButton content="Cancel" onClick={() => {
                            navigate('..')
                        }}/>
                    </div>
                    <div>
                        {
                            imgBase64 && imgBase64.preview ? <ConfirmationDialog text="Confirm" confirmText="Confirm create campaign" onClick={onCreateCampaign}  handleClose={handleClose} handleOpen={handleOpen} open={open} setOpen={setOpen}/> : <RedButton content="Confirm" onClick={handleOpen} disabled />
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
        <UploadImageDialog
            open={openUpload}
            close={(flag: boolean) => setOpenUpload(flag)}
            uploadDataString={(data) => setImgBase64(data)}
        />
    </>);
}

export default CreateCampaign;
