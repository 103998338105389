import React, { useState } from 'react'
import { Button, Snackbar } from "@mui/material";
interface CopytoClipboardProp {
    uniCode: string;
}

const CopytoClipboard = ({uniCode} : CopytoClipboardProp) =>   {
    const clipboardText = uniCode;
    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(clipboardText);
        setOpen(true);
    }

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Button
                sx = {{
                    backgroundColor: "#D7282F",
                    color: "white",
                    borderRadius: "50px",
                    height: "40px",
                    width: "100px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    "&:hover": {
                        backgroundColor: "#C30715",
                    }
                }}
                onClick={copyToClipboard} 
            >
                คัดลอก
            </Button>
            <Snackbar
                message="คัดลอกโค้ดแล้ว"
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{ height: "95%", color: "white",
                "& .MuiSnackbarContent-root": {
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    borderRadius: "15px",
                    fontSize: "25px",
                    fontWeight: "bold",
                }
                }}
            />
        </React.Fragment>
    )

}

export default CopytoClipboard
