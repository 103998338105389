import axios from "axios";

const callRedeem = async (userId: number, promotionId: number, qty: number): Promise<string[]> => {
    try {
        const res = await axios.post(
            "/reward-promotion/redeem-unique-code",
            { userId, promotionId, qty },
            {
                baseURL: process.env.REACT_APP_API_BASE_URL || "",
            }
        );
        return res.data.uniqueCode;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export default callRedeem;
