import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import IOSSwitch from "../../Components/Switch/switch";
import IconPicButton from "../../Components/Button/iconPicBtn";
import UploadImageDialog from "../UploadImageDialog/uploadImageDialog";
import "./style.css";
import CTLButton from "../../Components/Button/cancelBtn";
import {useNavigate, useParams} from "react-router-dom";
import { Box } from "@mui/system";
import InputField from "../../Components/InputField/inputfield";
import AutocompleteField from "../../Components/AutocompleteField/autocompleteField";
import {IupdatePromotion} from "../../Interface/promotion.interface";
import {updatePromotion} from "../../Services/promotion-management.service";
import Loading from "../../Components/Loading/loading";
import ConfirmationDialog from "../../Components/ConfirmationDialog/confirmationDialog";
import SuccessAlert from "../../Components/SuccessAlert/successAlert";
import ErrorAlert from "../../Components/ErrorAlert/errorAlert";
import { DeleteForever } from "@mui/icons-material";
import RedButton from "../../Components/RedButton/redbutton";
import BackButton from "../../Components/BackButton/backButton";
import { useLocation } from "react-router-dom";
import { IPrmotionList } from "../../Interface/promotion.interface";
import getPromotionById from "../../Services/getPromotionById";

const EditPromotion = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [imgBase64, setImgBase64] = useState<{ data: string; preview: string } | undefined>();
    const [name, setName] = React.useState("");
    const [point, setPoint] = useState<number>(0);
    const [status, setStatus] = React.useState({
        isActive: false,
    });
    const changeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatus({...status, [event.target.name]: event.target.checked});
    };
    const promotionTypeSets = ["Unique Code", "Non-unique Code"];
    const [promotionType, setPromotionType] = useState(promotionTypeSets[0]);
    const conditionSets = ["OneTime", "Unlimited"];
    const [conditionSelected, setConditionSelected] = useState(conditionSets[0]);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const savedAdminId = localStorage.getItem("adminId");
    const adminIdJSON = JSON.parse(savedAdminId || "0");
    const adminId = Number(adminIdJSON);

    const onUpdatePromotion = async () => {
        const data: IupdatePromotion = {
            id: Number(state.id),
            promotionName: name,
            promotionType: promotionType,
            condition: conditionSelected,
            buCode: "OE",
            point: Number(point),
            campaignId: Number(state.group),
            imageBase64: imgBase64?.data || '',
            isActive: status.isActive,
        };

        handleClose();
        setLoading(true);
        const response = await updatePromotion(data, adminId);
        if (response.statusCode === 200) {
            setLoading(false);
            setAlertOpen(true);
            setAlertContent('Edit sub-campaign successfully');
            setTimeout(() => {
                setAlertOpen(false);
                navigate('..');
            }, 1000);
        } else {
            setLoading(false);
            setAlertOpen(true);
            setAlertContent(response.message);
            setTimeout(() => {
                setAlertOpen(false);
            }, 1000);
        }
    }

    const { state } = useLocation();
    const [promotionInfo, setPromotionInfo] = useState<IPrmotionList>();
    useEffect(() => {
        if (!promotionInfo) {
            setPromotionInfo(state);
        }
    },[state]);

    useEffect(() => {
        if (promotionInfo) {
                getPromotionById(Number(promotionInfo?.id)).then((res) => {
                    setName(res.value.promotionName);
                    setPoint(res.value.point);
                    setStatus({...status, isActive: res.value.isActive});
                    setPromotionType(res.value.promotionType);
                    setConditionSelected(res.value.conditionValue);
                    setImgBase64({data: '', preview: res.value.imageUrl || ''});
                });
        }},[promotionInfo]);         
    
    return (
        <>
        <Loading isLoading={isLoading}/>
        <Box 
            sx={{ position: "absolute", top: "0", right: "0"}} >
                {
                    alertOpen && alertContent === 'Edit sub-campaign successfully' ? <SuccessAlert open={alertOpen} content={alertContent} /> : <ErrorAlert open={alertOpen} content={alertContent} />
                }
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", position : "relative", top: "10px", left: "10px" }}>
                <BackButton />
            </Box>
            <div className="Admin-background  content">
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item justifyContent="flex-start" alignItems="center">
                    <Typography variant="h4" component="div"
                                sx={{
                                    color: "#000000",
                                    letterSpacing: "1px",
                                    flexGrow: 1,
                                    display: "flex",
                                    position: "relative",
                                    top: "10px",
                                    left: "10px",
                                    fontWeight: "bold",

                                }}>
                        Edit sub-campaign
                        {
                            promotionInfo && <Typography variant="h4" component="div"
                                sx={{ color: "#000000", letterSpacing: "1px", flexGrow: 1, display: "flex", position: "relative", top: "10px", left: "10px", fontSize: "15px" }}>
                                for {promotionInfo?.name}
                            </Typography>
                        }
                    </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="stretch"
                        direction="row"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            sm={6}
                        >
                            <Grid item>
                                <AutocompleteField
                                    text={"Type"}
                                    width={"90%"}
                                    value={promotionType}
                                    onChange={(data) => setPromotionType(data)}
                                    options={promotionTypeSets}
                                />
                            </Grid>
                            <Grid item>
                                <InputField
                                    width={"90%"}
                                    text={"Name"}
                                    value={name}
                                    onChange={(data) => setName(data)}
                                />
                            </Grid>
                            <Grid item>
                                <AutocompleteField
                                    text={"Condition"}
                                    width={"90%"}
                                    value={conditionSelected}
                                    onChange={(data) => setConditionSelected(data)}
                                    options={conditionSets}
                                />
                            </Grid>
                            <Grid item>
                                <InputField
                                    digits
                                    width={"90%"}
                                    text={"Point"}
                                    value={point}
                                    onChange={(data) => setPoint(data)}
                                />
                            </Grid>
                            <Grid>
                                <div className="switch1">
                                <Typography variant="h6" component="div"
                                            sx={{
                                                color: "#000000",
                                                fontSize: "25px",
                                                letterSpacing: "1px",
                                                flexGrow: 1,
                                                display: "flex",
                                                position: "relative",
                                                left: "20px",
                                            }}
                                >
                                    {'Status '}
                                </Typography>
                                    <IOSSwitch
                                        checked={status.isActive}
                                        onChange={changeStatus}
                                        name="isActive"
                                        sx = {{position: "relative", left: "-140px"}}
                                    />
                                {
                                    status.isActive ? <Typography variant="h6" component="div" sx={{
                                        fontSize: "20px",
                                        letterSpacing: "1px",
                                        flexGrow: 1,
                                        display: "flex",
                                        position: "relative",
                                        left: "-130px",
                                    }}>Enable</Typography> : <Typography variant="h6" component="div" sx={{
                                        fontSize: "20px",
                                        letterSpacing: "1px",
                                        flexGrow: 1,
                                        display: "flex",
                                        position: "relative",
                                        left: "-130px",
                                    }}>Disable</Typography>

                                }
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        sm={6}
                    >

                        <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-start">
                            <Typography variant="h6" component="div"
                                        sx={{
                                            color: "#000000",
                                            fontSize: "25px",
                                            letterSpacing: "1px",
                                            flexGrow: 1,
                                            display: "flex",
                                            position: "relative",
                                            left: "20px",
                                            marginTop: "15px",
                                        }}
                            >
                                {'Image '}
                                <Typography sx = {{fontSize: "15px", color: "#000000", letterSpacing: "1px", position: "relative", left: "10px", top: "5px"}}>
                                    <span style={{color: "#FF0000"}}>*</span> Required
                                </Typography>
                            </Typography>
                            <div>
                                {imgBase64 && imgBase64.preview ? (
                                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Box sx={{marginTop: "15px"}}>
                                    <img
                                        style={{width: "80%"}}
                                        src={imgBase64.preview}
                                        alt={name}
                                    />
                                    </Box>
                                    <Box sx={{position: "relative", left: "-50px", top: "10px"}}>
                                        <DeleteForever onClick={() => setImgBase64({data: '', preview: ''})} sx={{color: "red", fontSize: "35px", cursor: "pointer"}}/>
                                    </Box>
                                    </Box>
                                ) : null}
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {
                                        imgBase64 && imgBase64.preview ? null : 
                                        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
                                        <Grid item sm={6}>
                                            <div className="button-add">
                                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-12px"}}>
                                                    <IconPicButton
                                                        content="Upload image"
                                                        onClick={() => setOpenUpload(true)}
                                                    />
                                                </Box>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    }
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <hr/>
                </Grid>
                <Grid item container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{marginBottom: "20px"}}
                >
                    <div>
                        <CTLButton content="Cancel" onClick={() => {
                            navigate('..')
                        }}/>
                    </div>
                    <div>
                        {
                            imgBase64 && imgBase64.preview ? <ConfirmationDialog text="Confirm" confirmText="Confirm edit sub-campaign" onClick={onUpdatePromotion}  handleClose={handleClose} handleOpen={handleOpen} open={open} setOpen={setOpen}/> : <RedButton content="Confirm" onClick={handleOpen} disabled />
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
        <UploadImageDialog
            open={openUpload}
            close={(flag: boolean) => setOpenUpload(flag)}
            uploadDataString={(data) => setImgBase64(data)}
        />
        </>
    );
};
export default EditPromotion;
