import axios from "axios";

const adminLogin = async (email: string) => {
    try {
        const res = await axios.post("admin/login", {
            email,
        }, 
        {
            baseURL: process.env.REACT_APP_API_BASE_URL || "",
        });
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export default adminLogin;
