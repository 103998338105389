import React from 'react'
import maincoverImage from "../../Assets/Image/maincover.png"
import "./maincover.css"

const maincover = () => {
  return (
    <React.Fragment>
        {/* Section 1: Main Cover */}
        <div className="container maincovers">
          <div className="canvas maincover-canvas">
          <div className="maincover">
            <img src={maincoverImage} alt="maincoverimg" />
          </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default maincover
