import axios from "axios";

import {LibraryPromotion} from "../Interface/promotion.interface";

const getPromotionLibraryList = async (userId?: string): Promise<LibraryPromotion[] | []> => {
    const config = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        params: {userId},
    };
    try {
        const res = await axios.get("/reward-promotion/library", config);
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export default getPromotionLibraryList;
