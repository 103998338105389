import React from 'react'
import Login from '../Login/login'
import OndemandLogo from '../../Assets/Image/ondemand_logo.png'
import "../Navbar/navbar.css"

const Navbar = () => {
  return (
    <nav className="navbar container">
        <img src={OndemandLogo} 
             alt="ondemand-logo" 
             onClick={() => window.location.href = "/"} />
        <Login />
    </nav>
    )
}

export default Navbar
