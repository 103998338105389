import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface duplicateCodeProps {
    duplicateCode: string[];
}

const DuplicateCodeModal = ({ duplicateCode }: duplicateCodeProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Button onClick={handleOpen}
        sx={{
            fontSize: "20px",
            fontWeight: "bold",
            borderRadius: "50px",
            padding: "10px 20px",
            width: "200px",
        }}
      >
        <Typography variant='h6'>
            Unique Code ซ้ำกัน {duplicateCode.length} โค้ด
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx = {{
           "& .MuiBox-root": {
            borderRadius: "50px",
            border: "2px solid #ffffff",
        },
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: "center"}}>
            <h3>โค้ดที่ซ้ำกัน</h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start" }}>
            {
                duplicateCode && duplicateCode.length > 0 ? (
                    duplicateCode.map((item: any) => {
                        return (
                            <Box sx={{ padding: "1px 5px", margin: "3px" }}>
                                <Typography sx={{ fontSize: "20px"}}>
                                {item}
                                </Typography>
                            </Box>
                        )
                    })
                ) : <></>
            }
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default DuplicateCodeModal;
