import "./adminLogin.css";
import googlelogo from "../../Assets/Image/google-logo.png";
import RedButton from "../../Components/RedButton/redbutton";

import OndemandLogo from "../../Assets/Image/ondemand_logo.png";
import OndemandRewards from "../../Assets/Image/ondemand-rewards.png";

import React, { useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import adminLogin from '../../Services/adminLogin';

export interface ILoginPageProps {}

const AdminLoginContainer: React.FunctionComponent<ILoginPageProps> = (
    props
) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [authing, setAuthing] = useState(false);

    const signInWithGoogle = async () => {
        setAuthing(true);

        signInWithPopup(auth, new GoogleAuthProvider())
            .then((response) => {
                // validate call api check admin email before navigate to admin page
                adminLogin(response.user.email || '').then((res) => {
                    if (res.isSuccess) {
                        localStorage.setItem("adminId", JSON.stringify(res.userId));
                        localStorage.setItem("adminName", JSON.stringify(response.user.displayName));
                        navigate("/console/campaign");
                    } else {
                        setAuthing(false);
                    }
                }).catch(() => {
                    setAuthing(false);
                })
            })
            .catch((error) => {
                console.log(error);
                setAuthing(false);
            });
    };

    return (
        <div className="adminlogin-container">
            <div className="adminlogin-image">
                <img
                    className="ondemand-logo"
                    src={OndemandLogo}
                    alt="ondemand-logo"
                    onClick={() => (window.location.href = "/console")}
                />
                <img
                    className="ondemand-rewards-logo"
                    src={OndemandRewards}
                    alt="Ondemand-rewards-logo"
                    onClick={() => (window.location.href = "/")}
                />
            </div>
            <div className="adminlogin-card">
                <h1 className="adminlogin-title">ONDEMAND WEB REWARD POINTS</h1>

                <div className="google-button">
                    <img
                        className="google-logo"
                        src={googlelogo}
                        alt="google-logo"
                        height="30px"
                    />
                    <RedButton
                        onClick={() => signInWithGoogle()}
                        disabled={authing}
                        content={"Sign in with Google Account"}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminLoginContainer;

// import React from "react";
// import { getAuth, signOut } from "firebase/auth";

// export interface IHomePageProps {}

// const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
//   const auth = getAuth();

//   return (
//     <div>
//       <p>Home Page (Protected by Firebase!)</p>
//       <button onClick={() => signOut(auth)}>Sign out of Firebase</button>
//     </div>
//   );
// };

// export default HomePage;
