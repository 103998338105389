import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CreateButton from "../../Components/CreateButton/createButton";
import RedButton from "../../Components/RedButton/redbutton";
import { allCampaignsList } from "../../Services/campaign.service";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ICampaign } from "../../Interface/campaign.interface";
import dayjs from "dayjs";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "name",
        headerName: "Name",
        width: 175,
        headerAlign: "center",
    },
    {
        field: "startDate",
        headerName: "Start",
        width: 75,
        valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY "),
        headerAlign: "center",
        align: "center",
    },
    {
        field: "endDate",
        headerName: "End",
        valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY"),
        width: 75,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "isActive",
        headerName: "Enable",
        type: "boolean",
        width: 75,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "imageUrl",
        headerName: "Picture",
        width: 150,
        editable: true,
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => (
            <>
                <img
                    className="MenuItem"
                    src={params.row.imageUrl}
                    alt="promotion"
                />
            </>
        ),
    },
    {
        type: "action",
        field: "actions",
        headerName: "Manage",
        headerAlign: "center",
        width: 400,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        minHeight: "500",
                        marginLeft: "auto",
                    }}
                >
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                        <RedButton content="Edit" fontSize="20px" onClick={() => row.onOpenPromtionList(`./${row.id}/editcampaign`, row) } />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                    <RedButton content="Delete" fontSize="20px" onClick={() => row.onOpenPromtionList(`./${row.id}/deletecampaign`, row) } />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                    <RedButton
                        content="Sub Campaign"
                        onClick={() =>
                            row.onOpenPromtionList(`./${row.id}`, row)
                        }
                        fontSize="20px"
                    />
                    </Box>
                </Box>
            );
        },
    },
];
const CampaignList = () => {
    const navigate = useNavigate();
    const [campaignList, setCampaignList] = useState<ICampaign[] | []>([]);
    useEffect(() => {
        allCampaignsList().then((data) => {
            setCampaignList(data);
        });
    }, []);
    const openPromtionList = (to: string, data: any) => {
        navigate(to, {
            state: {
                id: data.id,
                name: data.name,
                startDate: data.startDate,
                endDatee: data.endDate,
                imageUrl: data.imageUrl,
                isActive: data.isActive,
                description: data.description,
            },
        });
    };
    const rows = campaignList.map((campaign: ICampaign) => ({
        ...campaign,
        onOpenPromtionList: openPromtionList,
    }));

    const [pageSize, setPageSize] = useState<number>(10);
    return (
        <React.Fragment>
            <Box>
                <Box sx={{ height: 200, width: "100%" }}>
                    <CreateButton
                        content="Create New Campaign"
                        onClick={() => navigate("./create")}
                    />
                </Box>
                <Box
                    sx={{
                        height: 550,
                        padding: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        onPageSizeChange={(newPage) => setPageSize(newPage)}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        disableDensitySelector
                        disableColumnMenu
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};
export default CampaignList;
