import axios from "axios";

import { AllPromotions } from "../Interface/promotion.interface";

const getPromotionList = async (userId? : string): Promise<AllPromotions | []> => {
    let config;
    if(userId){
        config = {
            baseURL: process.env.REACT_APP_API_BASE_URL,
            params: { userId },
        };
    }
    else{
        config = {
            baseURL: process.env.REACT_APP_API_BASE_URL,
        };
    }
    try {
        const res = await axios.get("/reward-promotion/promotions", config);

        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export default getPromotionList;
