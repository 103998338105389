import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";

interface IAutocompleteFieldProp {
    text: string
    width?: string
    options: string[]
    value?: string
    onChange: (value: string) => void
}

const AutocompleteField = ({text, width, value, onChange, options}: IAutocompleteFieldProp) => {
    const [inputType, setInputType] = React.useState("");
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                    flexDirection: "column",
                }}
                noValidate
                autoComplete="off"
                component="form"
            >
                <Typography variant="h6" component="div"
                            sx={{
                                color: "#000000",
                                fontSize: "25px",
                                letterSpacing: "1px",
                                flexGrow: 1,
                                display: "flex",
                                position: "relative",
                                left: "20px",
                            }}
                >
                    {text}
                </Typography>
                <Autocomplete
                    value={value}
                    onChange={(
                        _event: any,
                        newType: string | null
                    ) => {
                        onChange(newType ?? '');
                    }}
                    inputValue={inputType}
                    onInputChange={(_event, newInputType) => {
                        setInputType(newInputType);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    fullWidth
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params}
                                   sx={{
                                       width: width,
                                       padding: '10px',
                                       "& .MuiOutlinedInput-root": {
                                           "& input": {
                                               padding: "10px 14px",
                                               fontSize: "20px",
                                               color: "#000000",
                                               textAlign: "flex-start",
                                               position: "relative",
                                               left: "20px",
                                           },
                                           "& fieldset": {
                                               borderRadius: "50px",
                                           },
                                           "&.Mui-focused fieldset": {
                                               borderColor: "#c4c4c4",
                                           },
                                           "&.MuiAutocomplete-inputRoot": {
                                                  padding: "4px 14px",
                                                  fontSize: "20px",
                                             },
                                        
                                       }

                                   }}/>
                    )}
                />
            </Box>
        </React.Fragment>
    );
}

export default AutocompleteField;
