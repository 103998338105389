import React, { useEffect, useState } from "react";
import { Chip, FormControl, Input, Box, Typography } from "@mui/material";
import RedButton from "../RedButton/redbutton";
import CancelButton from "../Button/cancelBtn";
import createUniqueCode from '../../Services/createUniqueCode';
import Modal from "../DuplicateCodeModal/DuplicatecodeModal";
import {useNavigate} from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";
import SuccessAlert from "../SuccessAlert/successAlert";
import ErrorAlert from "../ErrorAlert/errorAlert";
import LoadingPage from "../Loading/loading";
interface InputChipProps {
    promotionId?: number
}

const InputChip = ({ promotionId }: InputChipProps) => {
      const navigate = useNavigate();
      const [uniqueCodeArray, setUniqueCodeArray] = useState<string[]>([]);
      const [inputValue, setInputValue] = useState("");
      const [duplicateCode, setDuplicateCode] = useState<string[]>([]);
      const [isLoading, setLoading] = useState(false);
      const [open, setOpen] = useState(false);
      const [alertOpen, setAlertOpen] = useState(false);
      const [alertContent, setAlertContent] = useState('');

        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
  
        const handleKeyUp = (event: any) => {
            if (event.key === "Enter") {
              const newSelectedItem = [...uniqueCodeArray];
              const duplicatedValues = newSelectedItem.indexOf(
                event.target.value.trim()
              );
        
              if (duplicatedValues !== -1) {
                setInputValue("");
                return;
              }

              if (!event.target.value.replace(/\s/g, "").length) {
                setInputValue("");
                return;
              }
        
              newSelectedItem.push(event.target.value.trim());
              setUniqueCodeArray(newSelectedItem);
              setInputValue("");
            }

            if (
              uniqueCodeArray.length &&
              !inputValue.length &&
              event.key === "Backspace"
            ) {
              setUniqueCodeArray(uniqueCodeArray.slice(0, uniqueCodeArray.length - 1));
              if (uniqueCodeArray && uniqueCodeArray.length) {
                setDuplicateCode([]);
              }
            }
          }
  
      useEffect(() => {
          console.log(uniqueCodeArray);
      }, [uniqueCodeArray]);
  
      const handleChange = (event: any) => {
          event.preventDefault();
          setInputValue(event.target.value);
    };
    
    const handleDelete = (item: any, index: any) =>{
      let arr = [...uniqueCodeArray]
      arr.splice(index,1)
      setUniqueCodeArray(arr)
      setDuplicateCode([])
    }

    const handleClear = () => {
        setUniqueCodeArray([])
        setDuplicateCode([])
        setInputValue("")
    }

    const pasteHandler = (event: React.ClipboardEvent) => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text');
        let arr = paste.replace(/\s/g, '').split(',')
        let newArr = [...uniqueCodeArray]
        let dupArr = [...duplicateCode]

        arr.forEach((item: any) => {
            if (newArr.indexOf(item) === -1) {
                newArr.push(item)
            } else {
                dupArr.push(item)
            }
        }
        )
        setUniqueCodeArray(newArr)
        setDuplicateCode(dupArr)
        setInputValue("")
    }

    const onSubmit = async () => {
            handleClose();
            setLoading(true);
            const response = await createUniqueCode(Number(promotionId), uniqueCodeArray);
            if (response.statusCode === 200) {
                setLoading(false);
                setAlertOpen(true);
                setUniqueCodeArray([]);
                setAlertContent('Create unique code successfully');
                setTimeout(() => {
                    setAlertOpen(false);
                    navigate('..');
                }, 1000);
            }
            else {
                setLoading(false);
                setAlertOpen(true);
                setAlertContent(response.message);
                setTimeout(() => {
                    setAlertOpen(false);
                }, 1000);
            }
    }

      return (
        <React.Fragment>
            <LoadingPage isLoading={isLoading} />
            <Box 
                sx={{ position: "absolute", top: "0", right: "0" }}>
                {
                    alertOpen && alertContent === 'Create unique code successfully' ? <SuccessAlert open={alertOpen} content={alertContent} /> : <ErrorAlert open={alertOpen} content={alertContent} />
                }
            </Box>
              <FormControl
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    "& .MuiInputBase-root": {
                        width: "50%",
                        height: "100%",
                        padding: "0 10px",
                        fontSize: "16px",
                        color: "#000",
                        border: "1px solid #000",
                        borderRadius: "5px",
                        "&:focus": {
                            outline: "none",
                        },
                    },
                }}
              >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        color: "#000",
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        flexDirection: "column",
                    }}
                >
                    {
                        uniqueCodeArray && uniqueCodeArray.length > 0? <h2>{uniqueCodeArray.length} unique code will be created</h2> : <h2>Please enter unique code</h2>
                    }
                    {
                        duplicateCode && duplicateCode.length > 0? <Modal duplicateCode={duplicateCode} /> : <></>
                    }
                </Typography>
                  <Input
                      value={inputValue}
                      onChange={handleChange}
                      onKeyDown={handleKeyUp}
                      onPaste={pasteHandler}
                      placeholder="Enter Unique Code"
                      sx = {{maxWidth: "550px",
                        "& .MuiInputBase-input": {
                            padding: "10px 0",
                            fontSize: "20px",
                        },
                    }}
                  />
                  <Box
                    sx={{
                        maxHeight: "400px",
                        maxWidth: "55%",
                        overflowY: "auto",
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        marginTop: "10px",
                        "& > :not(style)": {
                        m: 0.5,
                        },
                    }}
                  >
                  <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap-reverse",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        marginTop: "10px",
                        padding: "0 10px",
                    }}
                  >
    
                      {uniqueCodeArray.map((item,index) => (
                          <Chip size="medium" onDelete={()=>handleDelete(item,index)} label={item}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                margin: "3px",
                                "& .MuiChip-label": {
                                    fontSize: "16px",
                                    color: "#000",
                                    padding: "0 10px",
                                },
                            }}
                          />
                      ))}
                      
                   </Box>
                  </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                           
                                 {
                                    uniqueCodeArray && uniqueCodeArray.length > 0 ? <ConfirmationDialog text="Confirm" confirmText="Confirm create unique code" onClick={onSubmit}  handleClose={handleClose} handleOpen={handleOpen} open={open} setOpen={setOpen} /> : <RedButton content="Confirm" onClick={onSubmit} disabled />
                                 }
                           
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                            <CancelButton onClick={handleClear} content="Clear" />
                        </Box>
                    </Box>
              </FormControl>
        </React.Fragment>
      );
  }

export default InputChip;
