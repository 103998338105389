import React from 'react';
import {Outlet} from "react-router-dom";
import {Paper} from "@mui/material";

const AdminMainContainers = () => {
    return (<>
        <div style={{marginLeft: '20%',marginRight:'1rem', maxWidth: '80%'}}>
            <Paper>
                <Outlet />
            </Paper>
        </div>
    </>)
}
export default AdminMainContainers
