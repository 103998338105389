import { useState } from "react";
import {
    convertToRaw,
    EditorState,
    ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { WysiwygProp } from "../../Interface/wysiwyg.interface";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

// You can read doc ref in <https://jpuri.github.io/react-draft-wysiwyg/#/docs>
const Wysiwyg = ({
    htmlContent,
    setHtmlContent,
    toolbarOption,
    editorStyle,
}: WysiwygProp) => {
    const [editorState, setEditorState] = useState<EditorState>(() => {
        const blocksFromHTML = htmlToDraft(htmlContent);
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
        );
    })

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        setHtmlContent(
            draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
        );
    };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            editorStyle={editorStyle}
            toolbar={{
                options: toolbarOption || [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "embedded",
                    "remove",
                    "history",
                ],
            }}
        />
    );
};

export default Wysiwyg;
