import React, { useEffect, useState } from "react";
import InputChip from "../../Components/InputChip/InputChip";
import PromotionNameDisplay from "../../Components/PromotionNameDisplay/PromotionNameDisplay";
import { useLocation } from "react-router-dom";
import { IPrmotionList } from "../../Interface/promotion.interface";
import BackButton from "../../Components/BackButton/backButton";
import { Box } from "@mui/material";

const ContainerUniqueCodeContainer = () => {
    const { state } = useLocation();
    const [promotionInfo, setPromotionInfo] = useState<IPrmotionList>();
    useEffect(() => {
        if (!promotionInfo) {
            setPromotionInfo(state);
        }
    },[state]);
    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", position : "relative", top: "10px", left: "10px" }}>
                <BackButton />
            </Box>
                <PromotionNameDisplay
                promotionName={promotionInfo?.name}
                imageUrl={promotionInfo?.imageUrl}
                title="Create Unique Code"
            />
            <InputChip promotionId={Number(promotionInfo?.id)} />
        </React.Fragment>
    );
};
export default ContainerUniqueCodeContainer;
