
import { Button } from "@mui/material";
import React from "react";
import "./redbutton.css";

interface RedButtonProp {
  content: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => any;
  disabled?: boolean;
  fontSize?: string;
}


const RedButton = ({ content, onClick, disabled, fontSize }: RedButtonProp) => {
  return (
    <React.Fragment>
    <Button
      sx = {{
        backgroundColor: "#D7282F",
        border: "solid 1px #ffffff",
        color: "white",
        borderRadius: "50px",
        paddingLeft: "30px",
        paddingRight: "30px",
        height: "50px",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: fontSize ? fontSize : "25px",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#cb1622",
         }
        }}
    
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
    </React.Fragment>
  );
};

export default RedButton;
