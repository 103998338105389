import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
} from "@mui/material";
import React from "react";
import RedButton from "../RedButton/redbutton"
import "./couponCard.css";

interface CardProp {
    id: string;
    promotionName: string;
    promotionType: string;
    point?: number;
    disable?: boolean;
    buCode?: string;
    description?: string;
    imageUrl: string;
    viewFlag?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => any;
}

const CardStyle = (id: string) => ({
    id,
    maxWidth: 300,
    maxHeight: 500,
    bgcolor: "#C30715",
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
    marginTop: "20px",
    borderRadius: "15px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
});

const CouponCard = (prop: CardProp) => {
    const {id, imageUrl, promotionName, point, disable, onClick, viewFlag} = prop;
    return (
        <Card sx={CardStyle(id)}>
            <Box sx={{position: "relative"}}>
                <CardMedia
                    component="img"
                    image={imageUrl}
                    alt="imageCard"
                    sx={{
                        height: 150,
                        width: 270,
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                    }}
                />
                <CardContent>
                    <Typography
                        variant="body1"
                        component="div"
                        align="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="30px"
                    >
                        {promotionName}
                    </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                position: "relative",
                                top: "-15px",
                            }}
                        >
                            <Typography
                                variant="h1"
                                component="div"
                                align="center"
                                color="white"
                                fontWeight="bold"
                            >
                                {point}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                color="white"
                            >
                          <span
                              style={{
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  position: "relative",
                                  top: "-20px",
                              }}
                          >
                              คะแนน
                          </span>
                            </Typography>

                        </Box>
                    <hr
                        style={{
                            color: "white",
                            position: "relative",
                            top: "-10px",
                        }}
                    />
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        top: "-25px",
                    }}
                >
                    <RedButton onClick={onClick} content={viewFlag?"ดูโค้ด":"แลกคะแนน"}/>
                </Box>
                {disable ? (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            bgcolor: "rgba(0, 0, 0, 0.4)",
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#000000",
                                width: "100%",
                                height: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                color="white"
                                fontWeight="bold"
                                display="flex"
                                justifyContent="center"
                            >
                                รับสิทธิ์แล้ว
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <React.Fragment/>
                )}
            </Box>
        </Card>
    );
};

export default CouponCard;
