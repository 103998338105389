import React, { useState, useEffect } from "react";
import LandingpageImage from "../../Components/LandingpageImage/landingpageImage";
import "./landingpageCampaign.css";
import Tabs from '@mui/material/Tabs';

import getPromotionList from "../../Services/getPromotionList";

const LandingPage = () => {
    const [promotionList, setPromotionList] = useState({});
    
    const loadPromotionInfo = async () => {
        const data = await getPromotionList();
        setPromotionList(data);
    };

    useEffect(() => {
        loadPromotionInfo();
    }, []);
    

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <React.Fragment>     
        {/* Section 2: Reward Campaign */}
        <div className="container reward-campaigns">
          <div className="title-box">
            <h1 className="title">
              <span>ใช้คะแนนแลก</span> สิทธิพิเศษ
            </h1>
          </div>

          <div className="campaign">
            <div className="campaign-image">
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons= "auto"
                aria-label="scrollable auto tabs example"
                sx ={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'white',
                    },
                }}
            >
          {
            Object.values(promotionList).map((data: any) => data.map((d: any) => {
                if (d.campaignType === "Goods" || d.campaignType === "Special") {
                    return <LandingpageImage imageUrl={d.imageUrl} />;
                }
            }))
          }
        </Tabs>
           </div>
         </div>

          <div className="title-box">
            <h1 className="title">
              <span>ใช้คะแนนแลก</span> คูปองส่วนลด
            </h1>
          </div>

          <div className="campaign">
            <div className="shoponline-image">
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons= "auto"
                aria-label="scrollable auto tabs example"
                sx = {{'& .MuiTabs-indicator': {
                    backgroundColor: 'white',
                    }
                }}>

            {
            Object.values(promotionList).map((data: any) => data.map((d: any) => {
                if (d.campaignType === "ShopOnline") {
                    return <LandingpageImage imageUrl={d.imageUrl} />;
                }
            }))
          }

            </Tabs>

            </div>
         </div>
        </div>

      </React.Fragment>
    )
}
export default LandingPage;
