import AdminSidebar from "../Components/AdminSidebar/adminSidebar";
import AdminMainContainers from "../Containers/AdminMainContainers";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const AdminMainPage = () => {

    return (
        <>
            <AdminSidebar />
            <AdminMainContainers />
        </>
    )
}
export default AdminMainPage;
