import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    Box,
    Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import RedButton from "../RedButton/redbutton";
import CancelIcon from "@mui/icons-material/Cancel";
import FullDescription from "../FullDescription/fullDescription";
import RedeemSuccessDialog from "../RedeemSuccessDialog/RedeemSuccessDialog";
import callRedeem from "../../Services/callRedeem";
import { UserObjectInfo } from "../../Interface/user.interface";
import { PromotionInfo } from "../../Interface/promotion.interface";
import { EventConditionDialogProp } from '../../Interface/eventConditionDialog.interface'
import AddPointsBox from "../AddPointsBox/addPointsBox";

import "./EventConditionDialog.css";
import useUserInfo from "../../Hooks/user-info";

const EventConditionDialog = ({
    promotionData,
    onClose,
    open,
    setOpen,
    userObject,
    setLoading,
    loadUserProfileInfo,
}: EventConditionDialogProp) => {
    // Set quantity of items
    const { setUseInfo } = useUserInfo();
    const [qty, setQty] = useState(1);

    const [uniCode, setUniCode] = useState(['']);
    const [isShowRedeem, setIsShowRedeem] = useState(false);

    useEffect(() => {
        setQty(1);
    }, [open]);

    // Handle redeem function
    const handleRedeem = async () => {
        setOpen(false);
        setLoading(true);
        const result = await callRedeem(
            Number((userObject as UserObjectInfo).userId),
            (promotionData as PromotionInfo).id,
            qty
        );
        const olePoint = Number((userObject as UserObjectInfo).point);
        const newPoint = olePoint - promotionData.point * qty;
        const newUserObjectInfo = {
            ...userObject,
            point: newPoint,
        };
        setUseInfo(newUserObjectInfo);
        setUniCode(result);
        setIsShowRedeem(true);
        loadUserProfileInfo();
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                sx={{
                    "& .MuiDialog-paper": {
                        color: "white",
                        borderRadius: "15px",
                        overflow: "auto",
                        height: "max-content",
                    },
                }}
            >
                <DialogTitle sx={{ textAlign: "center" }}>
                    <CancelIcon
                        className="cancelIcon"
                        onClick={onClose}
                        sx={{
                            cursor: "pointer",
                            float: "right",
                            fontSize: "20px",
                            color: "black",
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            color: "black",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{ fontWeight: "Bold", textAlign: "center" }}
                            >
                                <h1>{promotionData.promotionName}</h1>
                                <img
                                    src={promotionData.imageUrl}
                                    alt="imageCard"
                                    style={{
                                        width: "80%",
                                        height: "100%",
                                        borderRadius: "15px",
                                        marginTop: "10px",
                                    }}
                                />
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    margin: "10px",
                                }}
                            >
                                {promotionData.promotionType !== "OneTime" ? (
                                    <h2>จำนวนสิทธิ์ที่ต้องการ</h2>
                                ) : (
                                    <React.Fragment />
                                )}
                            </Typography>
                            {promotionData.promotionType !== "OneTime" ? (
                                <AddPointsBox
                                    qty={qty}
                                    setQty={setQty}
                                    tdata={promotionData}
                                    userObject={userObject}
                                />
                            ) : (
                                <React.Fragment />
                            )}
                            <Typography
                                sx={{ textAlign: "center", marginTop: "10px" }}
                            >
                                <h2>รวมคะแนนที่ใช้</h2>
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "40px",
                                    position: "relative",
                                    top: "-20px",
                                }}
                            >
                                <h1>
                                    {promotionData.promotionType === "OneTime"
                                        ? promotionData.point
                                        : promotionData.point * qty}
                                </h1>
                                <p
                                    style={{
                                        fontSize: "20px",
                                        position: "relative",
                                        top: "-35px",
                                    }}
                                >
                                    คะแนน
                                </p>
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    position: "relative",
                                    top: "-20px",
                                }}
                            >
                                {promotionData.point * qty >
                                userObject.point ? (
                                    <p style={{ color: "red" }}>
                                        คะแนนไม่เพียงพอ คุณมี{" "}
                                        {Number(userObject.point)} คะแนน
                                    </p>
                                ) : (
                                    <p style={{ color: "black" }}>
                                        คุณมี {Number(userObject.point)} คะแนน
                                    </p>
                                )}
                            </Typography>
                            <DialogActions
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    position: "relative",
                                    top: "-30px",
                                }}
                            >
                                {promotionData.point * qty >
                                userObject.point ? (
                                    <RedButton
                                        content={"ยืนยันแลกคะแนน"}
                                        onClick={onClose}
                                        disabled={true}
                                    />
                                ) : (
                                    <RedButton
                                        content={"ยืนยันแลกคะแนน"}
                                        disabled={false}
                                        onClick={handleRedeem}
                                    />
                                )}
                                {promotionData.description ? (
                                    <FullDescription
                                        optiondata={true}
                                        description={promotionData.description}
                                    />
                                ) : (
                                    <React.Fragment />
                                )}
                            </DialogActions>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <RedeemSuccessDialog
                key="RedeemSuccessDialog_key"
                open={isShowRedeem}
                onClose={(e) => {
                    e.preventDefault();
                    setIsShowRedeem(false);
                }}
                uniCode={uniCode}
                promotionGroup={promotionData.promotionGroup}
                endDate={promotionData.endDate}
            />
        </React.Fragment>
    );
};

export default EventConditionDialog;
