import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';

interface AddPointsBoxProp {
    qty: number;
    setQty: (qty: number) => any;
    tdata: any;
    userObject: any;
}

const addPointsBox = ({ qty, setQty, tdata, userObject }: AddPointsBoxProp) => {

  return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
            }}
        >
            <RemoveCircleIcon
                onClick={() => {
                    if (qty > 1) {
                        setQty(qty - 1);
                    } else {
                        setQty(1);
                    }
                }}
                sx={{ fontSize: 30, margin: "10px" }}
                cursor="pointer"
            />
            <TextField
                type="tel"
                sx={{
                    width: "200px",
                    color: "black",
                    "& .MuiOutlinedInput-root": {
                        "& input": {
                            padding: "10px 14px",
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "#000000",
                            textAlign: "center",
                        },
                        "& fieldset": {
                            borderRadius: "50px",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#c4c4c4",
                        },
                    }
                }}
                value={qty}
                />
            <AddCircleIcon
                onClick={() => {
                    if (
                        userObject.point >=
                        tdata.point * (qty + 1)
                    ) {
                        setQty(qty + 1);
                    }
                }}
                sx={{ fontSize: 30, margin: "10px" }}
                cursor="pointer"
            />
        </Box>
  )
}

export default addPointsBox
