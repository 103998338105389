import React from "react";
import "./userProfile.css";

interface UserProfileProp {
    userId: string;
    point: number;
    fullname: string;
}

const userProfile = ({ point, fullname }: UserProfileProp) => {
    return (
        <div className="container user-profile">
            <div className="user-profile-canvas">
                <div className="name-title">
                    <h1>{fullname}</h1>
                </div>
                <div className="info">
                    <h4>คะแนนของคุณ</h4>
                     <h1>{Number(point).toString()} คะแนน</h1>
                </div>
            </div>
        </div>
    );
};

export default userProfile;
