import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import RedButton from "../RedButton/redbutton";
import CTLButton from "../../Components/Button/cancelBtn";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface alertDialogProps {
    text: string;
    confirmText: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => any;
    open: boolean;
    setOpen: (open: boolean) => any;
    handleClose: (e: any) => any;
    handleOpen: (e: any) => any;
}


const AlertDialog = ({ text, confirmText, onClick, open, setOpen, handleClose, handleOpen }: alertDialogProps) => {
    
  return (
    <React.Fragment>
      <Button onClick={handleOpen}
      sx = {{
        backgroundColor: "#D7282F",
        color: "white",
        borderRadius: "50px",
        paddingLeft: "30px",
        paddingRight: "30px",
        height: "50px",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "25px",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#cb1622",
         }
        }}
      >{text}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx = {{
            "& .MuiBox-root": {
             borderRadius: "50px",
             border: "2px solid #ffffff",
         },
         }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"
            sx = {{
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "10px",
            }}
          >
            {confirmText}
          </Typography>
          <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                             <RedButton content="Confirm" onClick={onClick} fontSize="20px"/>

                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                marginLeft: "5px",
                                marginRight: "5px",
                            }}
                        >
                            <CTLButton content="Cancel" onClick={handleClose} fontSize="20px"/>
                        </Box>
                    </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default AlertDialog;
