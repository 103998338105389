import React from 'react'
import RedButton from '../RedButton/redbutton';
import "./login.css";

const login = () => {
  const buCode : string = process.env.REACT_APP_BU_CODE || '';
  const clientId : string = process.env.REACT_APP_CLIENT_ID || '';
  const userPortalUrl : string = process.env.REACT_APP_USER_PORTAL_URL || '';

  const getPortal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
      window.location.href=`${userPortalUrl}/login/?redirect_uri=${window.location.origin}/oauth2callback&bu_code=${buCode}&client_id=${clientId}`;
  }
  
  return (
    <React.Fragment>
        <RedButton onClick={getPortal} content={window.location.pathname === "/" ? "เข้าสู่ระบบ" : "ออกจากระบบ"}/>
    </React.Fragment>
  )
}

export default login
