import axios from "axios";

const getPromotionById = async (id: number): Promise<any> => {
    try {
        const res = await axios.get(`/reward-promotion/get-promotion-by-id?promotionId=${id}`, {
            baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export default getPromotionById;
