import React, { useEffect } from "react";
import "./App.css";
import AppRouter from "./Routes";
const App = () => {
  return (
      <React.Fragment>
          <AppRouter />
      </React.Fragment>
  );
};

export default App;
