import React from 'react'
import { Box, Alert, AlertTitle } from '@mui/material'

interface SuccessAlertProps {
    open: boolean
    content: string
}

const successAlert = ({ open, content }: SuccessAlertProps) => {
  return (
    <React.Fragment>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {
                open && <>
                    <Alert severity="success">
                        <AlertTitle
                            sx={{ fontSize: "20px",color: "#000"}}>
                                {content}
                        </AlertTitle>
                    </Alert>
                </>
            }
        </Box>
    </React.Fragment>
  )
}

export default successAlert
