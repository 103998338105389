import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";

interface IDatePickerFieldProps {
    text: string
    width?: string
    value?: Dayjs
    resultDate: (value: Dayjs) => void
    isEndDate?: boolean,
}

const dateFormat = 'DD/MM/YYYY'

const DatePickerField = ({text, width, value, resultDate, isEndDate}: IDatePickerFieldProps) => {
    const onChangeDate = (newStartDate: Dayjs | null) => {
        if (isEndDate) {
            newStartDate?.hour(23).minute(59).second(59)
        }
        const dateString = newStartDate;
        resultDate(dateString ? dateString : dayjs());
    };
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                    flexDirection: "column",
                }}
                noValidate
                autoComplete="off"
                component="form"
            >
                <Typography variant="h6" component="div"
                            sx={{
                                color: "#000000",
                                fontSize: "25px",
                                letterSpacing: "1px",
                                flexGrow: 1,
                                display: "flex",
                                position: "relative",
                                left: "20px",
                            }}
                >
                    {text}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        inputFormat={dateFormat}
                        value={value}
                        onChange={onChangeDate}
                        renderInput={(params) => <TextField {...params} fullWidth
                                                            sx={{
                                                                width: width,
                                                                padding: '10px',
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& input": {
                                                                        padding: "10px 14px",
                                                                        fontSize: "20px",
                                                                        color: "#000000",
                                                                        textAlign: "flex-start",
                                                                        position: "relative",
                                                                        left: "20px",
                                                                    },
                                                                    "& fieldset": {
                                                                        borderRadius: "50px",
                                                                    },
                                                                    "&.Mui-focused fieldset": {
                                                                        borderColor: "#c4c4c4",
                                                                    },
                                                                }
                                                            }}/>}
                    />
                </LocalizationProvider>
            </Box>
        </React.Fragment>
    );
}

export default DatePickerField;
