import axios from "axios";

const getUserInfo = async (code: string, redirectUrl: string) => {
    try {
        const res = await axios.post(
            "user/info",
            { code, redirect_uri: redirectUrl },
            {
                baseURL: process.env.REACT_APP_API_BASE_URL || "",
            }
        );
        return res.data;
    }
    catch (err) {
        console.log(err);
    }
};

export default getUserInfo;
