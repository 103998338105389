import React from "react";
import PromotionsContainer from '../Containers/Promotions/promotion'
import Navbar from "../Components/Navbar/navbar";
import Footer from "../Components/Footer/footer";

const Promotions = () => {
    return (
        <div>
            <Navbar/>
            <PromotionsContainer/>
            <Footer/>
        </div>
    )
}
export default Promotions;
