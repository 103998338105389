import AdminLoginContainer from "../Containers/adminLogin/adminLogin";

const AdminLoginPage = () => {
    return (
        <div>
            <AdminLoginContainer />
        </div>
    );
};

export default AdminLoginPage;
