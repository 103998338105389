import React from "react";
import Maincover from "../Components/Maincover/maincover";
import LandingpageCampaignContainer from "../Containers/landingpageCampaign/landingpageCampaign";
import Regulation from "../Components/Regulation/regulation";
import Navbar from "../Components/Navbar/navbar";
import Footer from "../Components/Footer/footer";

const LandingPage = () => {
  return (
    <React.Fragment>
        <Navbar/>
        <Maincover />
        <LandingpageCampaignContainer />
        <Regulation />
        <Footer/>
    </React.Fragment>
  )
}
export default LandingPage;
