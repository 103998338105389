import axios from "axios";

const createUniqueCode = async (promotionId: number, uniqueCode: String[]) => {
    
    try {
        const res = await axios.post('/reward-promotion/create-unique-code', {
                promotionId,
                code: [...uniqueCode]
            },
            {baseURL: process.env.REACT_APP_API_BASE_URL},
        );
        return res.data
    } catch (error) {
        return {
            statusCode: 500,
            message: "Something went wrong."
          }
    }
};

export default createUniqueCode;
