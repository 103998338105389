import React from "react";
import "./RedeemSuccessDialog.css";
import RedeemSuccessBox from "../RedeemSuccessBox/redeemSuccessBox";
import RedeemFailedBox from "../RedeemFailedBox/redeemFailedBox";
import { RedeemSuccessDialogProp } from "../../Interface/redeemSuccessDialog.interface";

const RedeemSuccessDialog = ({
  uniCode,
  open,
  onClose,
  promotionGroup,
  viewFlag,
  endDate
}: RedeemSuccessDialogProp) => {
  return (
    <React.Fragment>
            {
                  uniCode && uniCode.length > 0 ? <RedeemSuccessBox open={open} onClose={onClose} uniCode={uniCode} promotionGroup={promotionGroup} viewFlag={viewFlag} endDate={endDate} /> : <RedeemFailedBox onClose={onClose} open={open} />
            }
    </React.Fragment>
  );
};

export default RedeemSuccessDialog;
