import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/system";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  
import CancelIcon from "@mui/icons-material/Cancel";
import RedeemUniqueCode from "../RedeemUniqueCode/redeemUniqueCode";

interface RedeemSuccessBoxProp {
    onClose: (e: any) => void;
    open: boolean;
    uniCode: string[];
    viewFlag?: boolean;
    promotionGroup: string;
    endDate: string;
}

const redeemSuccessBox = ({onClose, open, uniCode, viewFlag, promotionGroup, endDate }: RedeemSuccessBoxProp) => {
    return (
        <React.Fragment>
            <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth="sm"
                    sx={{
                    "& .MuiDialog-paper": {
                        color: "white",
                        borderRadius: "50px",
                        height: "max-content",
                    },
                    }}
                >
            <DialogTitle sx={{textAlign: "center"}}>
            <CancelIcon
                onClick={onClose}
                sx={{cursor: 'pointer', float: 'right', fontSize: '20px', color: 'black'}}/>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="redeem-event-item"
            sx={{
            color: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            height: "max-content",
            }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            {viewFlag ? null:<><h1 style={{ fontWeight: 300 }}>
                            {
                                promotionGroup === "Ondemand" ? "แลกสินค้าสำเร็จ!" : "แลกโค้ดสำเร็จ!"
                            }
                        </h1></>}
                        {
                            viewFlag ? null : <><CheckCircleIcon
                            sx={{ fontSize: 100, color: "green", padding: "10px" }}
                        /></>
                        }
            
                        <Box sx={{ display: "flex", justifyContent: "center", fontWeight: "bold", borderRadius: "15px" }}>
                        <h2>
                            {
                                promotionGroup === "Ondemand" ? "รหัสสินค้าของคุณ" : "โค้ดของคุณ"
                            }
                        </h2>
                        </Box>
                            {
                                uniCode.map((code) => {
                                        return (
                                            <RedeemUniqueCode uniCode={code} endDate={endDate} />
                                        )
                                    }
                                )
                            }
                        </Box>
            </DialogContentText>
            </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default redeemSuccessBox
