import React from 'react'
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface AdminProfileProp {
    name: string;
}

const adminProfile = ({ name }: AdminProfileProp) => {
  return (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
            position: "relative",
            left: "-5px",
        }}
    >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <AccountCircleIcon 
            color='disabled'
            sx={{ fontSize: 70, marginRight: '5px', position: 'relative', top: '3px'}} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center'}}>
        <Typography 
            sx = {{
                fontSize: '17px',
                fontWeight: '600',
                color: '#000000',
                textAlign: 'center',
                textTransform: 'uppercase',
                letterSpacing: '2px',
            }}
        >
            {name}
        </Typography>
        <Typography 
         sx={{ bgcolor: "#D7282F", color: "white", padding:"1px 15px", borderRadius: "5px", fontSize: "16px", fontWeight: "bold", textAlign: "center", width: "100px", marginLeft: "auto", marginRight: "auto", letterSpacing: "2px" }}
        >
            Admin
        </Typography>
        </Box>
        </Box>
    </Box>
  )
}

export default adminProfile;
