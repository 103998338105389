import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    typography: {
        fontFamily: "dbheavent",
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
              boxShadow: "none",
              "&.Mui-disabled": {
                color: "white",
                backgroundColor: "#9E9FA1",
                opacity: 0.7
              },
            },
        },
     },
     MuiToggleButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                boxShadow: "none",
                color: "black",
                backgroundColor: "white",
                fontSize: "25px",
                fontWeight: "bold",
                borderRadius: "50px",
                padding: "10px 20px",
                width: "150px",
                "&.Mui-selected": {
                    backgroundColor: "#D7282F",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#cb1622",
                    }
                },
    },
    },
    },
    MuiInput: {
        styleOverrides: {
            root: {
                "&.MuiInput-root:after": {
                    borderBottom: "none"
                },

            },
        },
    },
}});

root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
