import { useParams, useLocation } from 'react-router-dom';

const useQuery = (query: any) => {
    const { search } = useLocation();
    return new URLSearchParams(search).get(query);
}

const useParam = (param: any) => {
    return  useParams()[param]
}

export { useQuery, useParam }