import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';

interface InputFieldProps {
    text: string
    width?: string
    value?: string | number
    onChange?: (value: any) => void
    digits?: boolean
}

const InputField = ({text, width, value, onChange,digits}: InputFieldProps) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                    flexDirection: "column",
                }}
                noValidate
                autoComplete="off"
                component="form"
            >
                <Typography variant="h6" component="div"
                            sx={{
                                color: "#000000",
                                fontSize: "25px",
                                letterSpacing: "1px",
                                flexGrow: 1,
                                display: "flex",
                                position: "relative",
                                left: "20px",
                            }}
                >
                    {text}
                </Typography>
                <TextField id="outlined-basic"
                           sx={{
                               width: width,
                               padding: '10px',
                               "& .MuiOutlinedInput-root": {
                                   "& input": {
                                       padding: "10px 14px",
                                       fontSize: "20px",
                                       color: "#000000",
                                       textAlign: "flex-start",
                                       position: "relative",
                                       left: "20px",
                                   },
                                   "& fieldset": {
                                       borderRadius: "50px",
                                   },
                                   "&.Mui-focused fieldset": {
                                       borderColor: "#c4c4c4",
                                   },
                               }
                           }}
                           type={digits ? 'number' : 'string'}
                           value={value}
                           onChange={e => onChange ? onChange(e.target.value) : null}

                />
            </Box>
        </React.Fragment>
    );
}

export default InputField;
