import React, { useEffect, useState } from "react";
import { getPromotionByCampaign } from "../../Services/promotion-management.service";
import { Box } from "@mui/material";
import CreateButton from "../../Components/CreateButton/createButton";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import RedButton from "../../Components/RedButton/redbutton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IPrmotionList } from "../../Interface/promotion.interface";
import { ICampaign } from "../../Interface/campaign.interface";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
        headerAlign: "center",
    },
    {
        field: "point",
        headerName: "Point",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "stock",
        headerName: "Stock",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "used",
        headerName: "Used",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "remain",
        headerName: "Remain",
        width: 50,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "isActive",
        headerName: "Enable",
        type: "boolean",
        width: 75,
        headerAlign: "center",
    },
    {
        field: "imageUrl",
        headerName: "Picture",
        width: 150,
        editable: true,
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => (
            <>
                <img
                    className="MenuItem"
                    src={params.row.imageUrl}
                    alt="promotion"
                />
            </>
        ),
    },
    {
        type: "action",
        field: "actions",
        headerName: "Manage",
        headerAlign: "center",
        width: 400,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return (
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    minHeight: "500",
                    marginLeft: "auto",
                }}
                >
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                    <RedButton content="Edit" fontSize="20px" onClick={() => row.onNavigate('./editpromotion', row)} />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                    <RedButton content="Delete" fontSize="20px" onClick={() => row.onNavigate('./deletepromotion', row)} />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                        }}
                    >
                    <RedButton
                        content="Upload Code"
                        onClick={() =>
                            row.onNavigate(`./createuniquecode`, row)
                        }
                        fontSize="20px"
                    />
                    </Box>
                </Box>
            );
        },
    },
];
const PromotionList = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const [promotionList, setPromotionList] = useState<IPrmotionList[]>([]);

    const [campaingInfo, setCampaingInfo] = useState<ICampaign>();

    useEffect(() => {
        getPromotionByCampaign(id ? parseInt(id) : 0).then((data) => {
            setPromotionList(data);
        });
        if (!campaingInfo) {
            setCampaingInfo(state);
        }
    }, [state]);

    const navigatetoPage = (to: string, data: any) => {
        const promotionInfo: IPrmotionList = {
            id: data.id,
            group: data.group,
            name: data.name,
            point: data.point,
            stock: data.stock,
            used: data.used,
            remain: data.remain,
            startDate: data.startDate,
            endDate: data.endDate,
            isActive: data.isActive,
            imageUrl: data.imageUrl,
        };

        navigate(to, { state: promotionInfo });
    };

    const rows = promotionList.map((row: IPrmotionList) => ({
        ...row,
        onNavigate: navigatetoPage,
        campaignId: id,
    }));

    const [pageSize, setPageSize] = useState<number>(10);

    return (
        <React.Fragment>
            <Box>
                <Box sx={{ height: 200, width: "100%" }}>
                    <CreateButton
                        content='Create New Sub Campaign'
                        onClick={() => navigate("./create", { state: campaingInfo })}
                        campaignInfo={campaingInfo?.name}
                    />
                </Box>
                <Box
                    sx={{
                        height: 550,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        "& .low-stock": {
                            bgcolor: "#ffb3b1",
                            "&:hover": {
                                bgcolor: "#ffb3b1",
                            },
                        },
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPage) => setPageSize(newPage)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params) => {
                            const percentage =
                                (100 * params.row.remain) / params.row.stock;
                            return percentage <= 10.0 ? "low-stock" : "";
                        }}
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};
export default PromotionList;
