import React from 'react'
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CopytoClipboard from "../CopytoClipboard/CopytoClipboard";

interface RedeemUniqueCodeProp {
    uniCode: string;
    endDate: string;
}

const redeemUniqueCode = ({ uniCode, endDate }: RedeemUniqueCodeProp) => {
  return (
    <React.Fragment>
              <Box sx={{ display: "flex", justifyContent: "center", margin: "10px", padding: "8px", paddingX: "5px", borderRadius: "10px", outline: "1px solid #9E9FA1" }}>
                    <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginRight: "10px" }}>
                        <h3>{uniCode}</h3>
                    </Typography>
                    <CopytoClipboard uniCode={uniCode}/>
              </Box>
              <Box>
                    <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", top: "-10px"}}>
                        <h3>หมดอายุ {endDate.toString().slice(0,10).replace(/-/g,"/")}</h3>
                    </Typography>
              </Box>
    </React.Fragment>
  )
}

export default redeemUniqueCode
