import React from 'react'
import "./landingpageImage.css"

interface LandingpageImageProps {
    imageUrl: string
}

const landingpageImage = ({imageUrl}: LandingpageImageProps) => {

    return (
    <React.Fragment>
        {
            imageUrl && <img src={imageUrl} alt="landingpageImage" />
        }
    </React.Fragment>
  )
}

export default landingpageImage
