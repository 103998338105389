import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton/backButton';
interface CreateButtonProp {
    onClick: () => void;
    content: string;
    campaignInfo?: string;
  }

const CreateButton = ({onClick, content, campaignInfo}: CreateButtonProp) => {
    return (
        <>
        {
            window.location.pathname !== "/console/campaign" ? <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer", position : "relative", top: "10px", left: "10px" }}>
            <BackButton />
            </Box> : <></>
        }
        {
             window.location.pathname !== "/console/campaign" ?
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <AddCircleIcon 
                sx={{color: "green", fontSize: 70}}
                onClick={onClick}
                cursor="pointer"
            />
            <Typography variant="body1" sx={{fontWeight: "bold", fontSize: "30px", letterSpacing: "1px"}}>{content}</Typography>
            {
                 window.location.pathname !== "/console/campaign" ? <Typography variant="body1" sx={{fontSize: "20px", letterSpacing: "1px", position: "relative", top: "-10px" }}>{campaignInfo}</Typography> : <></>
            }
        </Box> :  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ marginTop: "32px", marginBottom: "20px" }}>
            <AddCircleIcon 
                sx={{color: "green", fontSize: 70}}
                onClick={onClick}
                cursor="pointer"
            />
            <Typography variant="body1" sx={{fontWeight: "bold", fontSize: "30px", letterSpacing: "1px"}}>{content}</Typography>
            {
                 window.location.pathname !== "/console/campaign" ? <Typography variant="body1" sx={{fontSize: "20px", letterSpacing: "1px", position: "relative", top: "-10px" }}>{campaignInfo}</Typography> : <></>
            }
            </Box>
        </Box>
        }

        </>
    );
  };
  
export default CreateButton;
