import React, {useMemo, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import {useDropzone} from 'react-dropzone';
import CancelIcon from "@mui/icons-material/Cancel";
import RedButton from '../../Components/RedButton/redbutton';
import { width } from '@mui/system';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '1rem',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

interface uploadDialog {
    open: boolean,
    close: (flag: boolean) => void,
    uploadDataString: (data: any) => void;
}

interface FilesWithPreview extends File {
    preview: string
}

const UploadImageDialog = ({open, close, uploadDataString}: uploadDialog) => {

    const [files, setFiles] = useState<FilesWithPreview[]>([]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 1048576,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file: File) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const closeDialog = () => {
        close(!open)
        setFiles([]);
    }
    const convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onAddImage = async (file: File,preview:string) => {
        const base64 = await convertBase64(file)
        uploadDataString({data : base64,preview: preview });
        closeDialog()
    }
    return (<>
        <Dialog open={open} maxWidth={'sm'} fullWidth={true} onClose={() => closeDialog()}>
            <DialogTitle>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx = {{padding: '1rem'}}>
                    <Typography variant={'h5'}
                                sx={{fontWeight: 500, fontSize: '1.75rem', position: 'relative', top: '5px', left: '10px'}}
                    >Please upload your image</Typography>
                    <CancelIcon onClick={() => closeDialog()}/>
                </Box>
            </DialogTitle>
            <DialogContent id="upload-dialog-Content">
                <div className="container">
                    <div {...getRootProps({style})}>
                    {
                        files.length > 0 ? <img src={files[0].preview} alt="preview" style={{width: '100%'}}/> :
                        <>
                            <input {...getInputProps()} />
                            <p>Drag and drop or click here to select files</p>
                        </>
                    }
                    </div>
                </div>
            </DialogContent>
            <DialogActions sx={{paddingLeft: '2rem', paddingRight: '2rem', paddingBottom: '1rem'}}>
                <RedButton content={'Add Image'} onClick={() => {
                    onAddImage(files[0],files[0].preview);
                }} disabled={files.length === 0}/>
            </DialogActions>
        </Dialog>
    </>)
}
export default UploadImageDialog

