import React from 'react'
import "./regulation.css";

const regulation = () => {
  return (
    <React.Fragment>
        {/* Section 3: Regulation */}
        <div className="container regulations">
          <div className="canvas">

            <div className="title-box">
              <h1 className="title">
                <div className="title-items"><span>เงื่อนไขการใช้</span> My Ondemand Reward</div>
              </h1>
            </div>

            <div className="text-box">
              <p>1. รายการใช้จ่ายที่สถาบันกวดวิชาออนดีมานด์ทุกสาขา และเว็บไซต์ Shop Online ของสถาบัน ทุก 25 บาท ผู้ถือสิทธิ์ <strong>“สงวนสิทธิ์เฉพาะนักเรียนที่ลงทะเบียนเรียนและมีเลขนักเรียนเท่านั้น”</strong> จะได้รับคะแนนสะสม My OnDemand Reward (“คะแนนสะสม”) 1 คะแนน เพื่อสะสมแลกรับของรางวัลหรือใช้เป็นส่วนลด จากรายการ My OnDemand Reward</p>
              <br />
              <p>2. คะแนนสะสม My OnDemand Reward ที่ได้รับตั้งแต่วันที่ 8 ธันวาคม 2564 จะมีอายุคะแนน 1 ปี นับจากวันที่ได้รับคะแนนสะสม เช่น ได้รับคะแนนวันที่ 10 ธันวาคม 2564 คะแนนนี้จะหมดอายุในวันที่ 10 ธันวาคม 2565 สำหรับคะแนนสะสมก่อนวันที่ 8 ธันวาคม 2564 เป็นไปตามเงื่อนไขเดิม คือ มีอายุ 2 ปี นับจากวันที่ได้รับคะแนนสะสม (หมดอายุ 31 ธันวาคม 2565) หรือจนกว่าทางโรงเรียนจะบอกยกเลิก</p>
              <br />
              <p>3. คะแนนสะสมไม่สามารถ แลก เปลี่ยน หรือ ทอนเป็นเงินสดได้</p>
              <br />
              <p>4. คะแนนสะสมที่ผู้ถือสิทธิ์ทำการแลกคะแนนแล้วจะถูกตัดออกจากคะแนนสะสมตามจำนวน</p>
              <br />
              <p>5. คะแนนสะสมที่แลกคะแนนแล้วไม่สามารถขอคืนได้ ไม่ว่ากรณีใดๆ ทั้งสิ้น</p>
              <br />
              <p>6. คะแนนสะสมจากยอดการใช้จ่ายจะถูกคำนวณคะแนนคงเหลือในระบบทุกสิ้นวัน</p>
              <br />
              <p>7. หากตรวจสอบแล้ว พบว่ามีการใช้คะแนนที่สถาบันกวดวิชาออนดีมานด์ทุกสาขา และเว็บไซต์ของออนดีมานด์ เกินกว่าจำนวนคะแนนคงเหลือของผู้ถือสิทธิ์ ทางสถาบันจะทำการตัดสิทธิ์การแลกคะแนนทันที</p>
              <br />
              <p>8. คะแนนสะสม สามารถโอนย้ายให้คนในครอบครัวที่มีนามสกุลเดียวกันได้ โดยโอนคะแนนไม่รวมเศษ เช่น คะแนนคงเหลือ 540 คะแนน จะโอนคะแนนได้ 500 คะแนนถ้วน ตามเงื่อนไขตามที่โรงเรียนกำหนด</p>
              <br />
              <p>9. ในกรณีที่ท่านมีความประสงค์ใช้คะแนนสะสม My OnDemand Reward ในการแลกรับสินค้าหรือบริการกับผู้ให้บริการที่เข้าร่วมแคมเปญกับสถาบันกวนวิชาออนดีมานด์ ทางสถาบันจะดำเนินการโอนข้อมูลส่วนบุคคลของท่านตามเงื่อนไขของแคมเปญ ให้แก่ผู้ให้บริการเฉพาะรายนั้นเพื่อการระบุตัวตนในการรับสิทธิ์ โดยผู้ให้บริการรายอื่นเหล่านั้นถือเป็นผู้ควบคุมข้อมูลส่วนบุคคลแยกต่างหากจากสถาบันกวดวิชาออนดีมานด์ ซึ่งท่านสามารถศึกษานโยบายการคุ้มครองข้อมูลส่วนบุคคลได้จากผู้ให้บริการรายอื่นเหล่านั้นได้โดยตรง</p>
              <br />
              <p>10. สถาบันกวดวิชาออนดีมานด์ขอสงวนสิทธิ์ในการยกเลิกคะแนนสะสม โปรแกรมการแลกคะแนน หรือเปลี่ยนแปลงเงื่อนไขสิทธิประโยชน์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
              <br />
              <p>11. กรณีมีข้อพิพาทคำตัดสินของสถาบันกวดวิชาออนดีมานด์ถือเป็นที่สิ้นสุด</p>
            </div>

          </div>
         </div>
    </React.Fragment>
  )
}

export default regulation
