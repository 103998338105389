import axios from "axios";
import { ICampaign, ICreateCampaignResponse } from "../Interface/campaign.interface";

const axiosConfig = { baseURL: process.env.REACT_APP_API_BASE_URL };

const createCampaign = async (
    campaign: ICampaign
): Promise<ICreateCampaignResponse> => {
    try {
        const data = { ...campaign, userId: "1" };
        const res = await axios.post(
            "/reward-promotion/create-campaign",
            data,
            axiosConfig
        );
        return res.data;
    } catch (err) {
        return {
            statusCode: 500,
            message: "Something went wrong."
          }
    }
};

const allCampaignsList = async (): Promise<ICampaign[] | []> => {
    try {
        const res = await axios.get(
            "/reward-promotion/all-campaign",
            axiosConfig
        );
        return res.data;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export { createCampaign ,allCampaignsList};
